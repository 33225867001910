import { getCourses } from '_core/crud/courses.crud';
import { useOrganization } from '_core/hooks/useOrganization';
import { useUser } from '_core/lite/hooks/useUser';
import { isThereInfantileCourse } from '_core/utils/education-levels';
import { useEffect, useState } from 'react';

export const useRouter = () => {
  const { isStudent, user } = useUser();
  const [isInfantile, setIsInfantile] = useState(false);
  // Inicialmente no mostramos la app hasta que tengamos la feature flag
  const [loadedApp, setLoadedApp] = useState(false);
  // const { value: showInfantile01 } = useFlags(FEATURE_FLAGS.SHOW_INFANTILE_01, false, user);
  const { tenantConfig } = useOrganization();
  useEffect(() => {
    // esperamos que la feature flag este cargada, para pintar ese theme u nada. Si no habria un flasazo de la app, sin estilos de infantil y luego con estilos
    if (tenantConfig !== undefined) {
      if (tenantConfig?.showKidsMode && user && isStudent) {
        fetchCourses();
      } else {
        setLoadedApp(true);
      }
    }
  }, [isStudent, tenantConfig, user]);

  async function fetchCourses(params = {}) {
    try {
      const response = await getCourses(params);
      setIsInfantile(isThereInfantileCourse(response.data.data));
      setLoadedApp(true);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setLoadedApp(true);
    }
  }

  return {
    isInfantile,
    loadedApp,
  };
};
