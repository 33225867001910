import { getConfig } from '@oneclick/react-lemonade-editor';

const translates = {
  en: {
    richText_placeholder: 'Write your answer here.',
  },
};

export function getLemonadeTranslates(apiEditor, apiVisor) {
  Object.entries(translates).forEach(([lang, translations]) => {
    getConfig().overwriteTranslationsByLang(lang, translations);
    apiEditor.overwriteTranslationsByLang(lang, translations);
    apiVisor.overwriteTranslationsByLang(lang, translations);
  });
}
