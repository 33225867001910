import {
  DLO,
  _collections,
  asset,
  auth,
  collections,
  contents,
  contentsBooks,
  courses,
  customTags,
  educationLevels,
  gamification,
  i18n,
  langs,
  learningObjectives,
  notifications,
  organization,
  programs,
  projection,
  projectsLevels,
  tasks,
  templates,
  topics,
  types,
  ui,
} from './store';
/**
 * Reexports
 */

export { default as i18next } from './i18n/i18n';
export { default as I18nProvider } from './i18n/I18nProvider';
export * from './layout/LayoutContext';
export * from './utils/utils';

/**
 * Ducks
 */

export const frontoffice = {
  i18n,
  auth,
  contentsBooks,
  contents,
  courses,
  types,
  gamification,
  educationLevels,
  learningObjectives,
  collections,
  topics,
  tasks,
  projection,
  _collections,
  DLO,
  ui,
  asset,
  langs,
  customTags,
  projectsLevels,
  notifications,
  templates,
  programs,
  organization,
};

export const reducers = {
  /*[conf.REDUCER_AUTH]: auth.reducer,
  [conf.REDUCER_I18N]: i18n.reducer,
  entities: combineReducers({
    [conf.REDUCER_CONTENTS_BOOKS]: contentsBooks.reducer,
    [conf.REDUCER_CONTENTS]: contents.reducer,
    [conf.REDUCER_COURSES]: courses.reducer,
    [conf.REDUCER_TYPES]: types.reducer,
    [conf.REDUCER_EDUCATION_LEVELS]: educationLevels.reducer,
    [conf.REDUCER_LEARNING_OBJECTIVES]: learningObjectives.reducer,
    [conf.REDUCER_LANGS]: langs.reducer,
    [conf.REDUCER_COLLECTIONS]: _collections.reducer,
    [conf.REDUCER_CUSTOM_TAGS]: customTags.reducer,
    [conf.REDUCER_PROJECTS_LEVELS]: projectsLevels.reducer,
    [conf.REDUCER_TASKS]: tasks.reducer,
    [conf.REDUCER_PROJECTION]: projection.reducer,
  }),
  [conf.REDUCER_UI]: ui.reducer,*/
};
