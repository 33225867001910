import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { REDUCER_USERS } from '../../app/store/conf';
import { getSchoolUsers } from '../crud/schools.crud';
import { typesRoles } from './auth.duck';

export const actionTypes = {
  FetchSchoolUsers: '[users] FETCH_SCHOOL_USERS',
  FetchSchoolUsersSuccess: '[users] FETCH_SCHOOL_USERS_SUCCESS',
  FetchSchoolUsersError: '[users] FETCH_SCHOOL_USERS_ERROR',
};

const initialState = {
  school_admins: [],
  loading: false,
  error: null,
};

export const reducer = persistReducer({ storage, key: REDUCER_USERS, whitelist: ['school_admins'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FetchSchoolUsers:
      return { ...state, loading: true, error: null };

    case actionTypes.FetchSchoolUsersSuccess: {
      const { users } = action.payload;
      return {
        ...state,
        school_admins: users || [],
        loading: false,
        error: null,
      };
    }

    case actionTypes.FetchSchoolUsersError:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
});

export const selectors = {
  getSchoolAdmins: (state) => state[REDUCER_USERS]?.school_admins || [],
  getLoading: (state) => state[REDUCER_USERS]?.loading || false,
  getError: (state) => state[REDUCER_USERS]?.error || null,
};

export const actions = {
  fetchSchoolUsers: (schoolGuid) => ({
    type: actionTypes.FetchSchoolUsers,
    payload: { schoolGuid },
  }),
  fetchSchoolUsersSuccess: (users) => ({
    type: actionTypes.FetchSchoolUsersSuccess,
    payload: { users },
  }),
  fetchSchoolUsersError: (error) => ({
    type: actionTypes.FetchSchoolUsersError,
    payload: error,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.FetchSchoolUsers, function* fetchSchoolUsersSaga(action) {
    try {
      const { schoolGuid } = action.payload;
      const { data } = yield getSchoolUsers(schoolGuid, typesRoles.SCHOOL_ADMIN);
      yield put(actions.fetchSchoolUsersSuccess(data));
    } catch (error) {
      yield put(actions.fetchSchoolUsersError(error));
    }
  });
}
