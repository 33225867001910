import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from './../Icon';

const Tag = ({ text, color, tooltip, size, icon, iconColor, onClick, styleText, classTooltip, style, iconLeft, iconSize }) => {
  function renderTag() {
    return (
      <div style={style} className={`tag tag--color-${color} tag--size-${size} ${onClick ? 'tag--clickable' : ''}`} {...(onClick ? { onClick } : {})}>
        {icon && iconLeft && (
          <div className="tag__icon">
            <Icon color={iconColor} type={icon} size={iconSize} />
          </div>
        )}
        <span className="tag__text" style={styleText}>
          {text}
        </span>
        {icon && !iconLeft && (
          <div className="tag__icon">
            <Icon color={iconColor} type={icon} size={iconSize} />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {tooltip && (
        <Tooltip classes={{ tooltip: `tag-tooltip ${classTooltip}`, popper: 'tag-tooltip__popper' }} title={tooltip} placement="top">
          {renderTag()}
        </Tooltip>
      )}
      {!tooltip && renderTag()}
    </>
  );
};

Tag.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.oneOf(['default', 'primary', 'success', 'blue', 'transparent']),
  size: PropTypes.oneOf(['default', 'big', 'medium']),
  iconColor: PropTypes.oneOf(['default']),
  onClick: PropTypes.func,
  iconLeft: PropTypes.bool,
  tooltip: PropTypes.string,
  classTooltip: PropTypes.string,
  style: PropTypes.object,
  styleText: PropTypes.object,
  iconSize: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
};

Tag.defaultProps = {
  text: '',
  color: 'default',
  size: 'default',
  iconColor: 'default',
  iconLeft: false,
  tooltip: '',
  classTooltip: '',
  style: {},
  styleText: {},
};

export default Tag;
