import { isStudent } from '_core/utils/user';
import { CONTENT_TYPES } from '_core/variables/constant';
import { ROUTES } from './constants/paths';

export const notificationsTypes = {
  TODO: 'toDo',
  SOCIAL: 'social',
};

export const notificationsSubtypes = {
  SOCIAL: {
    COMMENT: 'stream-comment',
    POST: 'stream-post',
    TENANT: 'tenant-post',
    ASSESSMENTCOMMENT: 'assessment-comment',
    JOBCOMMENT: 'job-comment',
  },
  TODO: {
    ASSIGNED: 'assessment-assigned',
    REASSIGNED: 'assessment-reassigned',
    EVALUATED: 'assessment-evaluated',
    ONEDAY: 'due-date-tomorrow',
    LATE: 'due-date-today',
    DELIVERATCOMPLETE: 'teacher-deliver-at-complete',
    PUBLISHPENDING: 'teacher-publish-jobs-pending',
    STUDENTDELIVER: 'teacher-job-delivered',
    ALLDELIVER: 'teacher-all-jobs-completed',
    RESOURCE_ASSIGNED: 'resource-assigned',
    RESOURCE_EVALUATED: 'resource-evaluated',
    RESOURCE_DELIVERED: 'resource-delivered',
    RECORD_DELIVERED: 'record-delivered',
  },
};

export const getTypeOfSubTypeNotification = (subtypeNotification) => {
  if (
    subtypeNotification === notificationsSubtypes.TODO.ASSIGNED ||
    subtypeNotification === notificationsSubtypes.TODO.REASSIGNED ||
    subtypeNotification === notificationsSubtypes.TODO.EVALUATED ||
    subtypeNotification === notificationsSubtypes.TODO.ONEDAY ||
    subtypeNotification === notificationsSubtypes.TODO.LATE ||
    subtypeNotification === notificationsSubtypes.TODO.DELIVERATCOMPLETE ||
    subtypeNotification === notificationsSubtypes.TODO.PUBLISHPENDING ||
    subtypeNotification === notificationsSubtypes.TODO.STUDENTDELIVER ||
    subtypeNotification === notificationsSubtypes.TODO.ALLDELIVER ||
    subtypeNotification === notificationsSubtypes.TODO.RECORD_DELIVERED
  ) {
    return notificationsTypes.TODO;
  } else if (
    subtypeNotification === notificationsSubtypes.SOCIAL.COMMENT ||
    subtypeNotification === notificationsSubtypes.SOCIAL.POST ||
    subtypeNotification === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT ||
    subtypeNotification === notificationsSubtypes.SOCIAL.JOBCOMMENT ||
    subtypeNotification === notificationsSubtypes.SOCIAL.TENANT
  ) {
    return notificationsTypes.SOCIAL;
  }
};

export const isNotificationTypeToDo = (typeNotification) => {
  return (
    typeNotification === notificationsSubtypes.TODO.ASSIGNED ||
    typeNotification === notificationsSubtypes.TODO.REASSIGNED ||
    typeNotification === notificationsSubtypes.TODO.EVALUATED ||
    typeNotification === notificationsSubtypes.TODO.ONEDAY ||
    typeNotification === notificationsSubtypes.TODO.LATE ||
    typeNotification === notificationsSubtypes.TODO.DELIVERATCOMPLETE ||
    typeNotification === notificationsSubtypes.TODO.PUBLISHPENDING ||
    typeNotification === notificationsSubtypes.TODO.STUDENTDELIVER ||
    typeNotification === notificationsSubtypes.TODO.ALLDELIVER
  );
};

export const isNotificationTypeSocial = (typeNotification) => {
  return (
    typeNotification === notificationsSubtypes.SOCIAL.COMMENT ||
    typeNotification === notificationsSubtypes.SOCIAL.POST ||
    typeNotification === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT ||
    typeNotification === notificationsSubtypes.SOCIAL.JOBCOMMENT ||
    typeNotification === notificationsSubtypes.SOCIAL.TENANT
  );
};

export function getRouteToClickInNotification(notification, user, enableAdvancedProgramming) {
  if (isNotificationTypeSocial(notification.type)) {
    return getSocialNotificationsRoute(notification, user);
  } else if (isNotificationTypeToDo(notification.type)) {
    return getTodoNotificationRoute(notification, user, enableAdvancedProgramming);
  }
}

export function getTodoNotificationRoute(notification, user, enableAdvancedProgramming) {
  const state = {
    courseGuid: notification?.data?.course?.guid,
    guid: notification?.data?.course?.guid,
    lessonGuid: notification?.data?.lesson?.guid,
    contentGuid: notification?.data?.content?.guid,
    assessmentGuid: notification?.data?.lesson_item?.guid,
    lessonItemGuid: notification?.data?.lesson_item?.guid,
    postGuid: notification?.data?.message?.parentMessageData?.lesson_item_guid || notification?.data?.message?.parent_guid || notification?.data?.message?.guid,
    comment: '1',
    contentType: notification?.data?.content?.type_guid || notification?.data?.message?.content?.type_guid,
    userGuid: notification?.data?.student?.guid || notification.person_guid,
  };

  const content = { ...state };
  const queryParams = state.contentType ? { assessmentType: state.contentType } : {};

  if (enableAdvancedProgramming) {
    queryParams.hideCover = 1;
  }

  const routeMap = {
    [notificationsSubtypes.TODO.ASSIGNED]: () => getRouteForContent(isStudent(user), content),
    [notificationsSubtypes.TODO.REASSIGNED]: () => getRouteForContent(isStudent(user), content),
    [notificationsSubtypes.TODO.EVALUATED]: () => getRouteForContent(isStudent(user), content, state?.userGuid),
    [notificationsSubtypes.TODO.DELIVERATCOMPLETE]: () => getRouteForContent(isStudent(user), content),
    [notificationsSubtypes.TODO.PUBLISHPENDING]: () => getRouteForContent(isStudent(user), content),
    [notificationsSubtypes.TODO.STUDENTDELIVER]: () => getRouteForContent(isStudent(user), content, state?.userGuid),
    [notificationsSubtypes.TODO.ALLDELIVER]: () => getRouteForContent(isStudent(user), content),
  };

  const pathKey = routeMap[notification.type]?.() || ROUTES.COURSE;

  return { pathKey, state, queryParams };
}

function getSocialNotificationsRoute(notification, user) {
  const { data } = notification;
  console.log(notification);
  const state = {
    courseGuid: data?.course?.guid,
    guid: data?.course?.guid,
    lessonGuid: data?.message?.parentMessageData?.lesson_guid || data?.lesson?.guid,
    postGuid: data?.message?.parentMessageData?.lesson_item_guid || data?.message?.parent_guid || data?.message?.guid,
    unitGuid: data?.section?.guid,
    assessmentGuid: data?.lesson_item?.guid,
    contentGuid: data?.content?.guid,
    userGuid: data?.student?.guid,
    comment: '1',
  };

  const routeConfig = {
    [notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT]: ROUTES.LESSON,
    [notificationsSubtypes.SOCIAL.JOBCOMMENT]: isStudent(user) ? ROUTES.LESSON : ROUTES.RESULTS_ASSESSMENT,
  };

  const pathKey = routeConfig[notification.type] || ROUTES.COURSE;

  return { state, pathKey };
}

function getRouteForContent(isStudentUser, content, userGuid) {
  const contentType = content.contentType;
  if (isStudentUser) {
    const routeMap = {
      [CONTENT_TYPES.TEST]: userGuid ? ROUTES.RESULTS_ASSESSMENT_INVIDIDUAL : ROUTES.ASSESSMENT,
      [CONTENT_TYPES.QUESTION]: userGuid ? ROUTES.RESULTS_ASSESSMENT_INVIDIDUAL : ROUTES.ASSESSMENT,
      [CONTENT_TYPES.VIDEOLESSON]: userGuid ? ROUTES.RESULTS_ASSESSMENT_INVIDIDUAL : ROUTES.ASSESSMENT,
      [CONTENT_TYPES.MINT]: ROUTES.LESSON,
    };
    return routeMap[contentType] || (userGuid ? ROUTES.RESULTS_ASSESSMENT_INVIDIDUAL : ROUTES.ASSESSMENT);
  } else {
    return ROUTES.RESULTS_ASSESSMENT;
  }
}

export function getTextNotificationsUnread(notificationsUnread) {
  let text = notificationsUnread;
  if (notificationsUnread >= 10) {
    text = '9+';
  }
  if (notificationsUnread >= 100) {
    text = '99+';
  }
  return text;
}
