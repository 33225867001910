const rolesLang = {
  R02: 'Teacher',
  R01: 'Student',
  R04: 'Publisher',
};

export const ROLES_SELECT = [
  { label: 'Profesor', value: 'teacher' },
  { label: 'Alumno', value: 'student' },
];

export function filterStudents(users = []) {
  return users.filter((item) => isStudent(item));
}

export function filterTeachers(users = []) {
  return users.filter((item) => isTeacher(item));
}

export function filterTeacherOwner(users = [], ownerGuid) {
  return users.find((user) => user.guid === ownerGuid);
}

// pondrá primero al que creó la clase
export function orderTeachers(users = [], ownerGuid) {
  // Encuentra el índice del usuario con el GUID especificado
  const index = users.findIndex((user) => user.guid === ownerGuid);

  if (index === -1) {
    return users;
  }

  // Si se encuentra el usuario, mueve al principio del array
  const [user] = users.splice(index, 1);
  return [user, ...users];
}

export function getUserNoteStatus(note) {
  let status = 'needs-reinforcement';
  if (note === '-') {
    status = 'empty';
  } else {
    if (note >= 5) {
      status = 'needs-ampliacion';
    }
    if (note >= 7) {
      status = 'good-progress';
    }
  }

  return status;
}

export function getUserNoteStatusPercent(note, total) {
  let status = 'needs-reinforcement';
  if (note === '-') {
    status = 'empty';
  } else if (note !== 0) {
    let percent = (note * 100) / total;
    if (percent >= 50) {
      status = 'needs-ampliacion';
    }
    if (percent >= 70) {
      status = 'good-progress';
    }
  }

  return status;
}

export function isTeacher(user) {
  return user?.role_guid === 'R02' || user === 'R02';
}

export function isStudent(user) {
  return user?.role_guid === 'R01' || user === 'R01';
}

export function isEditorial(user) {
  return (user && user.role_guid === 'R04') || user === 'R04';
}

export function isSchoolAdmin(user) {
  return (user && user.role_guid === 'R03') || user === 'R03';
}

export function getRoleUserString(user) {
  let role = '';
  if (isTeacher(user)) {
    role = 'teacher';
  } else if (isStudent(user)) {
    role = 'student';
  } else if (isEditorial(user)) {
    role = 'editorial';
  }
  return role;
}

export function isHelpdesk(user) {
  return (user && user.role_guid === 'R05') || user === 'R05';
}

export function isUserFromMicrosoft(oauthAccounts) {
  if (oauthAccounts && oauthAccounts.length > 0) {
    return Boolean(oauthAccounts.filter((item) => item.provider === 'microsoft').length > 0);
  }
  return false;
}

export function isUserFromGoogle(oauthAccounts) {
  if (oauthAccounts && oauthAccounts.length > 0) {
    return Boolean(oauthAccounts.filter((item) => item.provider === 'google').length > 0);
  }
  return false;
}

export function getIdLangRole(user) {
  const role = user.role_guid || user;
  return rolesLang[role];
}

export function isDisregardedProgram(course, user) {
  //return isEditorial(course.author_role_guid); //course.author_role_guid && isStudent(user) ? isEditorial(course.author_role_guid) : false;
  return course?.school_group_guid === null;
}

export function parseGuidUserToObject(users, guids = '') {
  let usersObject = [];
  let studentFind = null;
  for (const user of guids.split(',')) {
    studentFind = users.find((item) => item.guid === user);
    if (studentFind) {
      usersObject.push(studentFind);
    }
  }
  return usersObject;
}

export function parseGuidUserToObjectV3(users, guids = []) {
  let usersObject = [];
  let studentFind = null;
  for (const user of guids) {
    studentFind = users.find((item) => item.guid === user);
    if (studentFind) {
      usersObject.push(studentFind);
    }
  }
  return usersObject;
}

// el array de item_for de los lesson items viene con profesores. Con esta función se eliminan
export function filterItemForUsersStudents(usersGuid = [], allUsers = []) {
  return allUsers.filter((user) => usersGuid.includes(user.person_guid) && user.role_guid === 'R01').map((user) => user.person_guid);
}

function checkUserAssigned(filterSelected, user) {
  return filterSelected.includes(user.person_guid);
}

export function getAssignedStudents(users, filterSelected) {
  return users.map((user) => ({
    ...user,
    id: user.person_guid || user.student_guid || user.guid,
    name: user.student_name ? user.student_name + ' ' + user.student_lastname : user.name + ' ' + user.lastname,
    thumbnail: user.student_avatar || user.avatar,
    selected: filterSelected ? checkUserAssigned(filterSelected, user) : true,
    disabled: !user?.status || user?.status !== 'pending',
  }));
}

export function cleanEmail(email) {
  if (email && email.includes('##')) {
    return email.split('##')[1];
  }
  return email;
}
