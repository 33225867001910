import { loginOauth } from '_core/crud/auth.crud';
import { getParseDisciplines } from '_core/lite/parsers';
import { getOraculoToken } from '_core/modules/components/ssoOraculo/serviceOraculo';
import * as coreEntities from '_core/store/index';
import { CLIENT, CONTENT_TYPES, LTI_CONTENT_TYPES, typeContentsIcons } from '_core/variables/constant';
import { LOCALSTORAGES } from '_core/variables/localStorages';
import { TYPESFORMAT, TYPESVIEWER } from '_core/variables/typesFormat';
import amplitude from 'amplitude-js';
import { defaultConfig } from 'app/config/environment';
import { flatten } from 'lodash';
import { PERSIST_AUTH, PERSIST_CONTENTS_BOOKS, PERSIST_COURSES, PERSIST_GROUPS, PERSIST_UI } from '../store/conf';
import { COURSE_MODE } from './constants/course';
import { isTeams } from './teamsTab';
import { getExtension } from './uploadFile';

export function removeCSSClass(ele, cls) {
  const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
  ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, baseUrl, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken, loginContext /*, tokenExpiration, user*/ },
      } = store.getState();
      // const hostname = window?.location?.hostname;
      // const isSantillanaAlfa = hostname.includes(SANTILLANA_ALFA);
      if (authToken) {
        config.headers.Authorization = `${authToken}`;
      }
      if (loginContext) {
        config.headers['login_context'] = loginContext;
      }
      // if (authToken && !checkTokenIsValid(tokenExpiration)) {
      //   console.log('invalid token, go to logout ', authToken);
      //   //If token is expired, we intercept the request before send to the api and logout the user
      //   removeAllStorage();
      //   window.location.href = user.referrer ? user.referrer : '/auth/login';
      //   throw new axios.Cancel('Token is expired');
      // }
      config.baseURL = baseUrl;
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    function (response) {
      // response data
      return response;
    },
    function (error) {
      const {
        auth: { user },
      } = store.getState();
      if (error.response && error.response.status == 403) {
        removeAllStorage();
        window.location.href = user.referrer ? user.referrer : '/auth/login';
      }
      return {
        error: error.response,
      };
    }
  );
}
export function checkTokenIsValid(exp) {
  if (!exp) return true;
  return Date.now() < exp;
}

export function getTokenExpiration(token) {
  if (token) {
    const decodedJwt = parseJwt(token);
    let exp = decodedJwt?.exp;
    if (exp) exp = exp * 1000;
    return exp;
  } else return null;
}

export function getTokenUserDetails(token) {
  if (token) {
    const tokenData = parseJwt(token);
    const userData = {
      email: tokenData.email,
      name: tokenData.given_name,
      surname: tokenData.family_name,
      identity_provider: 'google',
      token: token,
    };

    return userData;
  } else return null;
}

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    jsonPayload = JSON.parse(jsonPayload);
    return jsonPayload;
  } catch (e) {
    console.error('Error parsing JWT ', e);
    return null;
  }
};

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, '');
    localStorage.setItem(key + '_expiresIn', '');
  } catch (e) {
    console.log('removeStorage: Error removing key [' + key + '] from localStorage: ' + JSON.stringify(e));
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + '_expiresIn');
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log('getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e));
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + '_expiresIn', schedule);
  } catch (e) {
    console.log('setStorage: Error setting key [' + key + '] in localStorage: ' + JSON.stringify(e));
    return false;
  }
  return true;
}

export function removeAllStorage() {
  removeStorage('persist:' + PERSIST_AUTH);
  removeStorage('persist:' + PERSIST_CONTENTS_BOOKS);
  removeStorage('persist:' + PERSIST_UI);
  removeStorage('persist:' + PERSIST_GROUPS);
  removeStorage('persist:' + PERSIST_COURSES);
  removeStorage('persist:' + PERSIST_COURSES);
  removeStorage('persist:' + PERSIST_COURSES);
  removeStorage(LOCALSTORAGES.ACCESS_PLATFORM_ON);
}

export function redirectIfReferrer(referrer) {
  if (referrer) window.location.href = referrer;
}

/**
 * Dado un array de objectos, devuelve un array de string con los campos con el atributo Property de los elementos
 * Si no hay elementos devuelve un array vacio
 * @param {array} elements
 * @param {string} property Ejemplo "guid"
 */
export function getArrayProperty(elements, property) {
  var array = elements ? elements.map((element) => element[property]) : [];
  return array;
}

/**
 * Dado un array de objectos, devuelve un array de string con los campos guid de los elementos
 * Si no hay elementos devuelve un array vacio
 * @param {array} elements
 */
export function getArrayGuid(elements) {
  var arrayGuid = getArrayProperty(elements, 'guid');
  return arrayGuid;
}

/**
 * Dado un array de objectos, devuelve un array con los campos id de los elementos
 * Si no hay elementos devuelve un array vacio
 * @param {array} elements
 */
export function getArrayId(elements) {
  var arrayId = getArrayProperty(elements, 'id');
  return arrayId;
}

/**
 * Devuelve la option cuyo value sea el pasado por paremtro
 * @param {array} options
 * @param {string} value
 */
export function getOptionByValue(options, value) {
  return options && value ? options.find((option) => option.value == value) : null;
}

/**
 * Devuelve la option cuyo guid sea el pasado por paremtro
 * @param {array} options
 * @param {string} value
 */
export function getOptionByGuid(options, guid) {
  return options && guid ? options.find((option) => option.guid == guid) : null;
}

/**
 * Devuelve las optiones cuyos values esten dentro de los pasados por parametro
 * @param {array} options
 * @param {array} values Array de strings
 */
export function getOptionsByValues(options, values) {
  return options && values ? options.filter((option) => values.includes(option.value)) : [];
}

export function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    bytes = bytes + ' bytes';
  } else if (bytes == 1) {
    bytes = bytes + ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
}

export const getRandomString = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const successResponse = (response) => {
  return response.status === 200 && response.data && response.data.status === 'success';
};

export const getStateByScore = (score) => {
  if (score >= 7) return 'success';
  if (score < 7 && score >= 5) return 'regular';
  if (score < 5) return 'bad';
};

export const ValidateEmail = (mail) => {
  const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  //return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
  return mail ? mail.match(regex) : false;
};

export const isValidString = (value) => {
  return value && value !== '' && value !== undefined;
};

export const isMultimediaContent = (type) => {
  return type === 'CTTY_03' || type === 'CTTY_04' || type === 'CTTY_07';
};

export const removeFileExtension = (name) => {
  return name.replace(/\.[^/.]+$/, '');
};

export const removeHtmlTagsFromString = (value) => {
  return value
    ? value
        .replace(/(<([^>]+)>)/gi, '')
        .replace('&nbsp;', '')
        .replace('&amp;', '&')
        .replace('&quot;', '"')
        .replace('&apos;', "'")
        .replace('&lt;', '<')
        .replace('&gt;', '>')
    : '';
};

export const roundNumber = (value = 0, countDecimals = 1) => {
  return hasDecimal(value) ? parseFloat(value).toFixed(countDecimals) : parseFloat(value);
};
export const hasDecimal = (value) => {
  return !Number.isInteger(value);
};

export const isStudentMode = (courseMode) => {
  return courseMode === COURSE_MODE.seeAsStudent;
};

export const isProjectionMode = (courseMode) => {
  return courseMode === COURSE_MODE.projection;
};

export function mobileCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function deviceCheck() {
  const userAgent = navigator.userAgent.toLowerCase();

  let check = false;
  if (/mobi|android|touch|tablet/i.test(userAgent)) {
    check = true;
  } else {
    check =
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }
  return check;
}

export function linkify(text) {
  var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  });
}

export function getIdTypeByExtension(url) {
  const extesion = getExtension(url);
  const types = TYPESFORMAT;
  let find = null;
  for (let type of types) {
    find = type.formats.find((item) => item.format === extesion);
    if (find) {
      find = type.guid;
      break;
    }
  }
  return find;
}

export function getNameFile(url) {
  const split = url.split('/');
  const name = split[split.length - 1];
  return name;
}

export function getExtensionFromFilename(filename) {
  return filename ? filename.split('.').pop() : null;
}

/**
 * Funcion que devuelve un identificador aleatorio
 */
export function guidGenerator() {
  let S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

export const getValidUrl = (url, token) => {
  if (!url) return url;
  if (url.startsWith('/files-storage/')) {
    let domain = defaultConfig.BASE_URL_;
    if (domain.endsWith('/')) domain = domain.slice(0, -1);
    return domain + url + '?token=' + token;
  } else return url;
};

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getEducationLevelLabel(elements, t) {
  return elements.map((element) => {
    return {
      ...element,
      level: t('education-levels:' + element['guid']),
    };
  });
}

export function getEducationYearsLabel(elements, t) {
  return elements.map((element) => {
    return {
      ...element,
      year: t('education-years:' + element['guid']),
    };
  });
}

export function getIconType(type) {
  return typeContentsIcons[type];
}

export function getIconByDisciplineCourse(discipline) {
  let icons = [
    { guid: 'ed1', iconType: 'science' },
    { guid: 'ed10', iconType: 'chess' },
    { guid: 'ed11', iconType: 'chess' },
    { guid: 'ed12', iconType: 'chess' },
    { guid: 'ed2', iconType: 'maths' },
    { guid: 'ed3', iconType: 'language' },
    { guid: 'ed4', iconType: 'chess' },
    { guid: 'ed5', iconType: 'chess' },
    { guid: 'ed6', iconType: 'chess' },
    { guid: 'ed7', iconType: 'chess' },
    { guid: 'ed8', iconType: 'geography' },
    { guid: 'ed9', iconType: 'history' },
  ];

  let theIcon = icons.find((icon) => icon.guid === discipline);

  return theIcon ? theIcon.iconType : 'science';
}

export function getGradientByDisciplineCourse(discipline) {
  const gradients = getParseDisciplines();
  let theGradient = gradients.find((gradient) => gradient.guid === discipline);

  return theGradient ? theGradient.gradient : 'linear-gradient(to top, #86e328, #00b775)';
}

export function calculateUnitConsumption(unit) {
  const lessons = unit.lessons;
  if (lessons.length === 1) return lessons[0].progress.progress;
  const allProgress = flatten(lessons.map((item) => item.progress));
  let averageProgress = allProgress.map((item) => item.progress).reduce((a, b) => a + b);
  if (!averageProgress) averageProgress = 0;
  else {
    averageProgress = averageProgress / allProgress.length;
  }
  return averageProgress;
}

export function isModeDebug() {
  return !isModeProduction();
}

export function isModeProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isInPublisher(defaultConfig) {
  return (defaultConfig.PUBLISHER_DOMAIN && window.location.hostname.indexOf(defaultConfig.PUBLISHER_DOMAIN) !== -1) || false;
}

export function s2ab(s) {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
}

export const cleanEventName = (name) => {
  return name.replace('CLASS_WITH_LESSON: ', '').replace('CONTENT_TASK_EVENT: ', '').replace('`', '').replace('´', '');
};

/**
 * Change the position of an item in an array, based on the initial position
 * @param {The array} arr
 * @param {The item original position} from
 * @param {The new position for the item} to
 */
export const movePositionInArray = (arr, from, to) => {
  var element = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, element);
};

export const removeElementFromArray = (arr, element) => {
  let newArr = arr;
  const idx = newArr.indexOf(element);
  if (idx > -1) {
    newArr.splice(idx, 1);
  }

  return newArr;
};

export const compareField = (a, b, field) => {
  if (a[field].toLowerCase() < b[field].toLowerCase()) {
    return -1;
  }
  if (a[field].toLowerCase() > b[field].toLowerCase()) {
    return 1;
  }
  return 0;
};

export const parseNavigatorLanguage = (language) => {
  if (isEdelvivesMX()) return 'es-MX';
  let parsedLanguage = language;
  if (parsedLanguage.includes('-')) {
    parsedLanguage = language.split('-')[0];
  }
  return parsedLanguage;
};

function removeAudioElements(string) {
  return string ? string.replace(/<audio.*audio>/, '') : string;
}

export function stripHtml(html) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = removeAudioElements(html);
  return tmp.textContent || tmp.innerText || '';
}

export function getUrlParam(param) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(param);
}

export function parseStringify(json) {
  return JSON.parse(JSON.stringify(json));
}

export function getLtiContentType(data) {
  let type = '';
  try {
    type = JSON.parse(data).type;
  } catch (error) {
    console.error('Error parsing lti content type', error);
  }

  return type;
}

export function getLTIContentIcon(data) {
  let type = getLtiContentType(data);
  if (type === LTI_CONTENT_TYPES.AUDIO) return 'CTTY_04';
  if (type === LTI_CONTENT_TYPES.VIDEO) return 'CTTY_03';
  if (
    type === LTI_CONTENT_TYPES.DOCUMENT ||
    type === LTI_CONTENT_TYPES.PDF ||
    type === LTI_CONTENT_TYPES.PPT ||
    type === LTI_CONTENT_TYPES.EXCEL ||
    type === LTI_CONTENT_TYPES.DOCX
  )
    return 'CTTY_01';
  if (type === LTI_CONTENT_TYPES.HTML) return 'CTTY_20';
}

export function getIsExerciseBoolean(isEvaluable, questionType) {
  return isEvaluable && questionType === 'exactMatch';
}

export function findInArrayByAttrs(array = [], string = '', attrs = []) {
  return array.filter((item) => {
    for (const attr of attrs) {
      if (item[attr].toLowerCase().includes(string.toLowerCase())) {
        return true;
      }
    }
    return false;
  });
}

export function getIsTabletScreenDimensions() {
  return window.innerWidth < 1024;
}

export function removeOBjectsFromArrayBasedOnAnotherArray(arr1, arr2) {
  let arr3 = arr1.filter(function (objFromA) {
    return !arr2.find(function (objFromB) {
      return objFromA.guid === objFromB.guid;
    });
  });
  return arr3;
}

// helper for the above calculate score
const getEpsilon = () => {
  var e = 1.0;
  while (1.0 + 0.5 * e !== 1.0) e *= 0.5;
  return e;
};

export const calculateScore = (numberOfQuestions, rankingScaleOfTest, indexOfQuestion, wasTouched, currentScore) => {
  if (wasTouched) return currentScore;

  if (indexOfQuestion + 1 === numberOfQuestions) {
    return (
      Math.round(
        100 *
          (rankingScaleOfTest -
            numberOfQuestions * parseFloat(rankingScaleOfTest / numberOfQuestions).toFixed(2) +
            rankingScaleOfTest / numberOfQuestions +
            Number.EPSILON || getEpsilon())
      ) / 100
    );
  }

  return parseFloat(rankingScaleOfTest / numberOfQuestions).toFixed(2);
};

export function isScorm(type) {
  return type === CONTENT_TYPES.SCORM;
}

export function isxApi(type) {
  return type === CONTENT_TYPES.XAPI;
}

export function isLemonadeTest(type) {
  return type === CONTENT_TYPES.TEST;
}

export function isVideolesson(type) {
  return type === CONTENT_TYPES.VIDEOLESSON;
}

export function isLemonadeQuestion(type) {
  return type === CONTENT_TYPES.QUESTION;
}

export function isTest(type) {
  return type === CONTENT_TYPES.TEST;
}

export function isMint(type) {
  return type === CONTENT_TYPES.MINT;
}

export function isLTI(type) {
  return type === 'CTTY_LTI';
}

export function isNativeAppWebview() {
  return navigator.userAgent.endsWith('EDP-app') || navigator.userAgent.endsWith('tangeringe-app--santillana');
}

export function isNativeAppWenViewOrTeams() {
  return isNativeAppWebview() || isTeams();
}

export function changeFavicon(url) {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = url;
}

export const isEmbedMode = (searchParams) => {
  let urlParams = new URLSearchParams(searchParams);
  return Boolean(urlParams.get('type') === 'embed');
};

export const makeFileNameSafe = (name) => {
  if (name) return name.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '_').toLowerCase();
  return '';
};

export const onMigrateToPolpFinished = (store) => {
  let oraculoId = store.getState()?.auth?.user?.oraculo_id;
  let oraculoToken = getOraculoToken();
  let tangerineToken = store.getState()?.auth.authToken;
  if (oraculoToken && tangerineToken && !oraculoId) {
    const identityProvider = 'oraculo';

    loginOauth(oraculoToken, identityProvider)
      .then((data) => {
        if (data.data.data.token) {
          store.dispatch(coreEntities.auth.actions.loginOraculo(data.data.data.token_id));
          store.dispatch(coreEntities.auth.actions.login(data.data.data));
        }
      })
      .catch((error) => {});
  }
};

export function fromBase64(base64) {
  return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
}

/**
 * Devuelve true si tiene el elemento el la clase className
 * @param {*} el
 * @param {*} className
 */
export function hasClass(el, className) {
  if (el.classList) return el.classList.contains(className);
  return !!el.className?.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

export function emitAmplitudeEvent(event, properties = {}) {
  // if (window.analytics) {
  //   window.analytics.track(event, { timestamp: Date.now(), tenant: window.tenant, environment: 'production', ...properties });
  // } else {
  //   amplitude.getInstance().logEvent(event, properties);
  // }
  amplitude.getInstance().logEvent(event, properties);
}

export function initUserForTracking(user, tenant = 'TANGERINE', environment = 'production') {
  let role = user.role_guid;
  if (role === 'R01') role = 'Student';
  if (role === 'R02') role = 'Teacher';

  try {
    window.analytics.identify(user.guid, {
      tenant: tenant,
      environment: environment,
      user: user.guid,
      name: user.name,
      lastname: user.lastname,
      role: role,
    });
  } catch (e) {
    console.error('Error in initUserForTracking', e);
  }
}

export function getLanguageByTenant(tenant, language) {
  if (tenant === CLIENT.GOTITBYME) return 'en';
  if (tenant === CLIENT.MASTER || tenant === CLIENT.MASTER_DEMO) return 'pt';
  return language || 'es';
}

export function showLog(...props) {
  if (process.env.REACT_APP_DEBUG === 'true') console.log(...props);
}

export function markdownToHtml(markdownText) {
  // Reemplaza los encabezados de nivel 1
  markdownText = markdownText.replace(/^# (.*)$/gm, '<h1>$1</h1>');

  // Reemplaza los encabezados de nivel 2
  markdownText = markdownText.replace(/^## (.*)$/gm, '<h2>$1</h2>');

  // Reemplaza los encabezados de nivel 3
  markdownText = markdownText.replace(/^### (.*)$/gm, '<h3>$1</h3>');

  // Reemplaza los encabezados de nivel 4
  markdownText = markdownText.replace(/^#### (.*)$/gm, '<h4>$1</h4>');

  // Reemplaza los encabezados de nivel 5
  markdownText = markdownText.replace(/^##### (.*)$/gm, '<h5>$1</h5>');

  // Reemplaza los encabezados de nivel 6
  markdownText = markdownText.replace(/^###### (.*)$/gm, '<h6>$1</h6>');

  // Reemplaza los énfasis con doble asterisco
  markdownText = markdownText.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>');

  // Reemplaza los énfasis con un asterisco
  markdownText = markdownText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>');

  // Reemplaza los enlaces
  markdownText = markdownText.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');

  // Reemplaza los saltos de línea
  markdownText = markdownText.replace(/(?:\r\n|\r|\n)/g, '<br>');

  return markdownText;
}

export function randomRGB() {
  let red = Math.floor(Math.random() * 256);
  let green = Math.floor(Math.random() * 256);
  let blue = Math.floor(Math.random() * 256);

  return [red, green, blue];
}

export function ParseXodExtension(path) {
  if (path.endsWith('.doc')) {
    return path.replace('.doc', '_doc.xod');
  }
  if (path.endsWith('.docx')) {
    return path.replace('.docx', '_docx.xod');
  }
  if (path.endsWith('.xls')) {
    return path.replace('.xls', '_xls.xod');
  }
  if (path.endsWith('.xlsx')) {
    return path.replace('.xlsx', '_xlsx.xod');
  }
  if (path.endsWith('.ppt')) {
    return path.replace('.ppt', '_ppt.xod');
  }
  if (path.endsWith('.pptx')) {
    return path.replace('.pptx', '_pptx.xod');
  }

  return path;
}

export function getNameForViewerTypeByExtension(url) {
  const extesion = getExtension(url);
  const types = TYPESVIEWER;
  let find = null;
  for (let type of types) {
    find = type.formats.find((item) => item.format === extesion);
    if (find) {
      find = type.name;
      break;
    }
  }
  return find;
}

export function isIframeElement(path) {
  return path?.includes('<iframe');
}

export function getRandomNumber() {
  return Math.floor(Math.random() * 26) + 1;
}

export function sanitizeMessages(messages) {
  // Sanitize each message
  let sanitizedMessages = messages.map((entry) => {
    return { ...entry, message: entry.message && sanitizeText(entry.message) };
  });

  return sanitizedMessages;
}

function sanitizeText(str) {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
}

export function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function detectMultimediaContentBaseOnPath(path) {
  // Define extensions for each file type
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  const videoExtensions = ['mp4', 'mov', 'wmv', 'flv', 'avi'];
  const audioExtensions = ['mp3', 'wav', 'aac', 'flac', 'ogg'];

  // Extract the file extension from the path
  const extension = path.split('.').pop().toLowerCase();

  // Check the file type and return the corresponding type
  if (imageExtensions.includes(extension)) {
    return 'CTTY_07';
  } else if (videoExtensions.includes(extension)) {
    return 'CTTY_03';
  } else if (audioExtensions.includes(extension)) {
    return 'CTTY_04';
  } else {
    return null;
  }
}

export function isEdelvivesMX() {
  return window.location.hostname.includes('edelvivesdigitalplus.com.mx') || window.location.hostname.includes('edelvivesdigital-mx-dev');
}

export function isLocalhost() {
  return window.location.href.includes('localhost');
}

export function isObjectEmpty(obj) {
  if (obj === null || obj === undefined) return true;
  return Object.keys(obj).length === 0;
}

export function isImage(url) {
  if (!url) return;
  return url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif') || url.endsWith('.bmp');
}

export function isVideo(url) {
  return url.endsWith('.mp4') || url.endsWith('.mov') || url.endsWith('.wmv') || url.endsWith('.flv') || url.endsWith('.avi');
}
