import axios from 'axios';
export const SEND_EVENTS = 'events';

export function sendEventToAnalytics(event, params) {
  return axios.post(`${SEND_EVENTS}/${event}`, params);
}

export function sendUserEvent(params) {
  return axios.post(`v3/analytics/events`, params);
}
