export const DEFAULT_LANG = 'es';

export const NEED_DEMO_GROUP = false;

export const MAX_FILE_UPLOAD_SIZE = 200; // size in megabytes

export const clientIdGoogle = {
  'http://localhost:3001': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://tangerine-qa1.oneclicklabs.es': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://tangerine-dev1.oneclicklabs.es': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://tangerine-latest.oneclick.es': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://digital.macmillaneducation.es': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://gotitbyme.com': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://khalamosdigital.com': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://proyectoalfa.santillana.cr': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://proyectoalfa.santillana.com.pa': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://proyectoalfa.santillana.com.ni': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://santillana-demo.oneclick.es': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://brunoevoluciona.com': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
  'https://librosactivos.com': '557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com',
};

export const getClientIdMicrosoft = (origin) => {
  return clientIdMicrosoft[origin] || '4b5cb50c-660c-404c-b485-bb494f437654';
};

export const getGlobalEducapUrl = () => {
  return null;
};

const clientIdMicrosoft = {
  'http://localhost:3001': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://localhost:3005': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://tangerineaws.tk': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://tangerineaws2.tk': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://tangerineqa1.tk': '03e8f4fd-54e6-47c3-a2bc-0f238c993a26',
  'https://tangerineqa12.tk': '03e8f4fd-54e6-47c3-a2bc-0f238c993a26',
  'https://tangerinedemo1.tk': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://tangerinedemo2.tk': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://tangerine.oneclick.es': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://macmillan-dev.oneclicklabs.es': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://macmillan-qa.oneclicklabs.es': '03e8f4fd-54e6-47c3-a2bc-0f238c993a26',
  'https://digital.macmillaneducation.es': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://macmillan-prod.oneclick.es': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://khalamosdigital.com': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://gotitbyme.com': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://santillana-demo.oneclick.es': '4b5cb50c-660c-404c-b485-bb494f437654',
};

export const getClientIdAmplitude = (origin) => {
  return clientIdAmplitude[origin] || 'd7a7d7d4541c149fdfdcf3ea24aafd74';
};

const clientIdAmplitude = {
  'http://localhost:3002': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://tangerine-dev1.oneclicklabs.es': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://alfa-santillana-dev.oneclicklabs.es': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://macmillan-dev.oneclicklabs.es': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://tangerine-latest.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://digital.macmillaneducation.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://macmillan-prod.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://alfa-santillana.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://proyectoalfa.santillana.cr': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://proyectoalfa.santillana.com.pa': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://proyectoalfa.santillana.com.ni': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://santillana-demo.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://master-editora.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
};

export const getClientIdFeatureFlag = (origin) => {
  return featureFlagId[origin] || 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ';
};

export const featureFlagId = {
  'http://localhost:3001': 'OBfcCG_3iEiBYr3gPQEhRQ/V4DQV120rkW37sFy92wvdg',
  'http://localhost:3005': 'OBfcCG_3iEiBYr3gPQEhRQ/V4DQV120rkW37sFy92wvdg',
  'https://tangerine-dev1.oneclicklabs.es': 'OBfcCG_3iEiBYr3gPQEhRQ/V4DQV120rkW37sFy92wvdg',
  'https://alfa-santillana-dev.oneclicklabs.es': 'OBfcCG_3iEiBYr3gPQEhRQ/V4DQV120rkW37sFy92wvdg',
  'https://macmillan-dev.oneclicklabs.es': 'OBfcCG_3iEiBYr3gPQEhRQ/V4DQV120rkW37sFy92wvdg',
  'https://tangerine-latest.oneclick.es': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
  'https://digital.macmillaneducation.es': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
  'https://macmillan-prod.oneclick.es': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
  'https://alfa-santillana.oneclick.es': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
  'https://proyectoalfa.santillana.cr': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
  'https://proyectoalfa.santillana.com.pa': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
  'https://proyectoalfa.santillana.com.ni': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
  'https://santillana-unoi.oneclick.es': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
  'https://santillana-demo.oneclick.es': 'OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ',
};

export const tenants = {
  macmillan: 'MACMILLAN',
  tangerine: '83d6cd67-0eab-4ddc-900f-4ddb70dc0d3d',
  edelvives: 'EDELVIVES_DIGITAL_PLUS',
};

export const CONFIG = {
  googleKeys: {},
  isBooksGradebookAllowed: true,
  gradebookScopes: [
    {
      label: 'grades:Workbook',
      value: 'toolbox',
    },
    {
      label: 'course:Program',
      value: 'program',
    },
  ],
};

export const defaultTimes = [
  {
    id: 'quarter1',
    days: [
      {
        guid: new Date().getTime(),
        value: ['08:00', '09:00'],
        day: 'monday',
      },
    ],
  },
  {
    id: 'quarter2',
    days: [
      {
        guid: new Date().getTime(),
        value: ['08:00', '09:00'],
        day: 'monday',
      },
    ],
  },
  {
    id: 'quarter3',
    days: [
      {
        guid: new Date().getTime(),
        value: ['08:00', '09:00'],
        day: 'monday',
      },
    ],
  },
];

export const TEMPLATES = {
  VANILLA: 'vanilla',
  RETRO: 'retro',
  PRIMARY: 'primary-2022',
  SECONDARY: 'secondary-2022',
  PQLCO: 'secondary-pqlco',
  UNOI: 'unoi',
};

export const DEMO_GROUP_PAYLOAD = {
  limit_courses: 0,
  limit_courses_jobs: 0,
  name: 'Mi clase demo',
  students: [
    {
      username: 'student01@oneclick.es',
    },
  ],
  messages: [],
};

export const COOKIES = {};

export const MATH_RENDER_ENGINE_TANGERINE = 'katex';
