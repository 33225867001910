import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

function Loading({ loadingText }) {
  // Verificar si existe la variable CSS --loading y limpiar el string
  const customLoading = getComputedStyle(document.documentElement)
    .getPropertyValue('--loading')
    .replace(/^url\(['"]?|['"]?\)$/g, '')
    .trim();
    
  return (
    <div className={clsx("loading", {
      "loading--custom": customLoading
    })}>
      <div className="loading__backdrop" />

      <div className="loading__wrapper">
        {customLoading ? (
          <img 
            className="loading__custom-image" 
            src={customLoading}
            alt="Loading"
          />
        ) : (
          <svg id="loading__svg" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.0013 29.3363C14.2016 29.3363 12.454 28.9847 10.8107 28.2893C9.22195 27.6173 7.79734 26.6537 6.57326 25.4296C5.34918 24.2056 4.38554 22.7809 3.7136 21.1922C3.01822 19.5488 2.66663 17.8013 2.66663 16.0016C2.66663 15.4833 3.08594 15.064 3.60422 15.064C4.1225 15.064 4.54181 15.4833 4.54181 16.0016C4.54181 17.5487 4.84392 19.0488 5.44294 20.463C6.02112 21.8277 6.84672 23.0544 7.89891 24.1066C8.95109 25.1588 10.1778 25.987 11.5425 26.5626C12.9541 27.159 14.4542 27.4611 16.0013 27.4611C17.5483 27.4611 19.0484 27.159 20.4626 26.56C21.8274 25.9818 23.054 25.1562 24.1062 24.104C25.1584 23.0518 25.9866 21.8251 26.5622 20.4604C27.1586 19.0488 27.4607 17.5487 27.4607 16.0016C27.4607 14.4546 27.1586 12.9545 26.5596 11.5403C25.9834 10.1788 25.1494 8.94159 24.1036 7.89667C23.0598 6.84948 21.8223 6.01533 20.46 5.4407C19.0484 4.84429 17.5483 4.54218 16.0013 4.54218C15.483 4.54218 15.0637 4.12286 15.0637 3.60458C15.0637 3.0863 15.483 2.66699 16.0013 2.66699C17.8009 2.66699 19.5485 3.01859 21.1919 3.71397C22.7806 4.38591 24.2052 5.34955 25.4293 6.57362C26.6533 7.7977 27.6144 9.22492 28.2863 10.811C28.9817 12.4544 29.3333 14.202 29.3333 16.0016C29.3333 17.8013 28.9817 19.5488 28.2863 21.1922C27.617 22.7809 26.6533 24.2056 25.4293 25.4296C24.2052 26.6537 22.778 27.6147 21.1919 28.2867C19.5485 28.9847 17.8009 29.3363 16.0013 29.3363V29.3363Z"
              fill="url(#loading-gradient-a)"
              stroke="url(#loading-gradient-b)"
            />
            <defs>
              <linearGradient id="loading-gradient-a" x1="2.66668" y1="2.96318" x2="34.7356" y2="15.3497" gradientUnits="userSpaceOnUse">
                <stop offset="0.123118" stopColor="#FF5029" />
                <stop offset="1" stopColor="#F91B6B" />
              </linearGradient>
              <linearGradient id="loading-gradient-b" x1="2.66668" y1="2.96318" x2="34.7356" y2="15.3497" gradientUnits="userSpaceOnUse">
                <stop offset="0.123118" stopColor="#FF5029" />
                <stop offset="1" stopColor="#F91B6B" />
              </linearGradient>
            </defs>
          </svg>
        )}
      </div>
      {loadingText && <div className="loading__text">{loadingText}</div>}
    </div>
  );
}

Loading.propTypes = {
  loadingText: PropTypes.string,
};

Loading.defaultProps = {
  loadingText: '',
};

export default Loading;
