import { sendUserEvent } from '_core/crud/analytics';
import { useUser } from '_core/lite/hooks/useUser';
import { parseEducationYearName } from '_core/lite/parsers';
import * as coreEntities from '_core/store/index';
import { emitAmplitudeEvent, isNativeAppWebview } from '_core/utils/utils';
import { AMPLITUDE_EVENT } from '_core/variables/constant';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useSystemSettings from './useSystemSettings';

const useAmplitude = () => {
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const getTenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));
  const currentCourse = useSelector((state) => coreEntities.courses.selectors.getCurrentCourse(state));
  const { enableAdvancedProgramming } = useSystemSettings();

  const { user } = useUser();

  function sendAmplitudeEvent(event, extraProperties, course = currentCourse || {}) {
    let schoolName = null;
    if (user?.schools && user?.schools?.length > 0) {
      schoolName = user?.schools[0]?.name;
    }

    let courseProperties = {
      discipline: course?.discipline_name,
      content_stage: course?.education_level_name,
      content_grade: parseEducationYearName(course?.education_year_name),
      course_guid: course?.guid,
      education_level_guid: course?.education_level_guid,
      education_year_guid: course?.education_year_guid,
      discipline_guid: course?.discipline_guid,
      collection_guid: course?.collections && course?.collections?.length > 0 ? course?.collections[0]?.guid : undefined,
      lang_guid: course?.digital_program_langs && course?.digital_program_langs?.length > 0 ? course?.digital_program_langs[0]?.id : undefined,
      country_guid: course?.country_guid,
      template_guid: course?.template_guid,
      program: course?.name,
      program_guid: course?.program_guid,
      school_name: schoolName,
    };
    emitAmplitudeEvent(event, {
      tenant: client,
      timestamp: new Date().getTime(),
      user_id: user?.guid,
      role_guid: user?.role_guid,
      is_app: isNativeAppWebview() ? 'yes' : 'no',
      school_state_guid: user?.schools?.length > 0 ? user?.schools[0]?.state_guid : null,
      school_city_guid: user?.schools?.length > 0 ? user?.schools[0]?.city_guid : null,
      school_guid: user?.schools?.length > 0 ? user?.schools[0]?.guid : null,
      ...courseProperties,
      ...extraProperties,
    });

    if (enableAdvancedProgramming) {
      sendUserEvent({
        event_type: event,
        tenant: getTenant,
        ocurred_on: moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
        attributes: {
          tenant: client,
          timestamp: new Date().getTime(),
          user_id: user?.guid,
          role_guid: user?.role_guid,
          school_state_guid: user?.schools?.length > 0 ? user?.schools[0]?.state_guid : null,
          school_city_guid: user?.schools?.length > 0 ? user?.schools[0]?.city_guid : null,
          school_guid: user?.schools?.length > 0 ? user?.schools[0]?.guid : null,
          ...courseProperties,
          ...extraProperties,
        },
      });
    }
  }

  return { sendAmplitudeEvent, AMPLITUDE_EVENT };
};

export default useAmplitude;
