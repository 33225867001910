import Image from '../../../_core/variables/images';
import { ReactComponent as BarMenuLogo } from '../../assets/img/logo/bar-menu-logo.svg';
import LogoBig from '../../assets/img/logo/logo-big.png';
import LogoIconInverse from '../../assets/img/logo/logo-icon-inverse.png';
import LogoIconS from '../../assets/img/logo/logo-icon-s.png';

const getCoreImage = Image.getImage;

Image.getImage = (id) => {
  let componentImage;
  switch (id) {
    case 'logoBig':
      componentImage = LogoBig;
      break;

    case 'logoIconS':
      componentImage = LogoIconS;
      break;

    case 'barMenuLogo':
      componentImage = BarMenuLogo;
      break;

    case 'logoIconInverse':
      componentImage = LogoIconInverse;
      break;

    default:
      componentImage = getCoreImage(id);
      break;
  }

  return componentImage;
};
