import auth_en from './en/auth.json';
import calendar_en from './en/calendar.json';
import common_en from './en/common.json';
import courses_en from './en/courses.json';
import editorial_en from './en/editorial.json';
import grades_en from './en/grades.json';
import header_en from './en/header.json';
import lemonade_en from './en/lemonade.json';
import lesson_en from './en/lesson.json';
import message_en from './en/message.json';
import profile_en from './en/profile.json';
import project_en from './en/project.json';
import signup_en from './en/signup.json';
import tasks_en from './en/tasks.json';
import teams_en from './en/teams.json';
import test_en from './en/test.json';
import { default as auth_en_gotitbyme, default as auth_es_gotitbyme } from './en_gotitbyme/auth.json';
import { default as calendar_en_gotitbyme, default as calendar_es_gotitbyme } from './en_gotitbyme/calendar.json';
import { default as common_en_gotitbyme, default as common_es_gotitbyme } from './en_gotitbyme/common.json';
import { default as courses_en_gotitbyme, default as courses_es_gotitbyme } from './en_gotitbyme/courses.json';
import { default as editorial_en_gotitbyme, default as editorial_es_gotitbyme } from './en_gotitbyme/editorial.json';
import { default as grades_en_gotitbyme, default as grades_es_gotitbyme } from './en_gotitbyme/grades.json';
import { default as header_en_gotitbyme, default as header_es_gotitbyme } from './en_gotitbyme/header.json';
import { default as kanban_en_gotitbyme, default as kanban_es_gotitbyme } from './en_gotitbyme/kanban.json';
import { default as lemonade_en_gotitbyme, default as lemonade_es_gotitbyme } from './en_gotitbyme/lemonade.json';
import { default as lesson_en_gotitbyme, default as lesson_es_gotitbyme } from './en_gotitbyme/lesson.json';
import { default as profile_en_gotitbyme, default as profile_es_gotitbyme } from './en_gotitbyme/profile.json';
import { default as project_en_gotitbyme, default as project_es_gotitbyme } from './en_gotitbyme/project.json';
import { default as signup_en_gotitbyme, default as signup_es_gotitbyme } from './en_gotitbyme/signup.json';
import { default as tasks_en_gotitbyme, default as tasks_es_gotitbyme } from './en_gotitbyme/tasks.json';
import { default as teams_en_gotitbyme, default as teams_es_gotitbyme } from './en_gotitbyme/teams.json';
import { default as test_en_gotitbyme, default as test_es_gotitbyme } from './en_gotitbyme/test.json';
import auth_en_macmillan from './en_macmillan/auth.json';
import calendar_en_macmillan from './en_macmillan/calendar.json';
import common_en_macmillan from './en_macmillan/common.json';
import courses_en_macmillan from './en_macmillan/courses.json';
import editorial_en_macmillan from './en_macmillan/editorial.json';
import grades_en_macmillan from './en_macmillan/grades.json';
import header_en_macmillan from './en_macmillan/header.json';
import lemonade_en_macmillan from './en_macmillan/lemonade.json';
import lesson_en_macmillan from './en_macmillan/lesson.json';
import profile_en_macmillan from './en_macmillan/profile.json';
import project_en_macmillan from './en_macmillan/project.json';
import signup_en_macmillan from './en_macmillan/signup.json';
import tasks_en_macmillan from './en_macmillan/tasks.json';
import teams_en_macmillan from './en_macmillan/teams.json';
import test_en_macmillan from './en_macmillan/test.json';
import auth_es from './es/auth.json';
import calendar_es from './es/calendar.json';
import common_es from './es/common.json';
import courses_es from './es/courses.json';
import editorial_es from './es/editorial.json';
import grades_es from './es/grades.json';
import header_es from './es/header.json';
import lemonade_es from './es/lemonade.json';
import lesson_es from './es/lesson.json';
import message_es from './es/message.json';
import profile_es from './es/profile.json';
import project_es from './es/project.json';
import signup_es from './es/signup.json';
import tasks_es from './es/tasks.json';
import teams_es from './es/teams.json';
import test_es from './es/test.json';
import auth_es_macmillan from './es_macmillan/auth.json';
import calendar_es_macmillan from './es_macmillan/calendar.json';
import common_es_macmillan from './es_macmillan/common.json';
import courses_es_macmillan from './es_macmillan/courses.json';
import editorial_es_macmillan from './es_macmillan/editorial.json';
import grades_es_macmillan from './es_macmillan/grades.json';
import header_es_macmillan from './es_macmillan/header.json';
import lemonade_es_macmillan from './es_macmillan/lemonade.json';
import lesson_es_macmillan from './es_macmillan/lesson.json';
import profile_es_macmillan from './es_macmillan/profile.json';
import project_es_macmillan from './es_macmillan/project.json';
import signup_es_macmillan from './es_macmillan/signup.json';
import tasks_es_macmillan from './es_macmillan/tasks.json';
import teams_es_macmillan from './es_macmillan/teams.json';
import test_es_macmillan from './es_macmillan/test.json';
import auth_pt from './pt/auth.json';
import calendar_pt from './pt/calendar.json';
import common_pt from './pt/common.json';
import courses_pt from './pt/courses.json';
import editorial_pt from './pt/editorial.json';
import grades_pt from './pt/grades.json';
import header_pt from './pt/header.json';
import lemonade_pt from './pt/lemonade.json';
import lesson_pt from './pt/lesson.json';
import message_pt from './pt/message.json';
import profile_pt from './pt/profile.json';
import project_pt from './pt/project.json';
import signup_pt from './pt/signup.json';
import tasks_pt from './pt/tasks.json';
import teams_pt from './pt/teams.json';
import test_pt from './pt/test.json';

export default {
  es: [
    auth_es,
    courses_es,
    common_es,
    header_es,
    calendar_es,
    lesson_es,
    profile_es,
    signup_es,
    tasks_es,
    test_es,
    lemonade_es,
    grades_es,
    project_es,
    editorial_es,
    teams_es,
    message_es,
  ],
  en: [
    auth_en,
    courses_en,
    common_en,
    header_en,
    calendar_en,
    lesson_en,
    profile_en,
    signup_en,
    tasks_en,
    test_en,
    lemonade_en,
    grades_en,
    project_en,
    editorial_en,
    teams_en,
    message_en,
  ],
  pt: [
    auth_pt,
    courses_pt,
    common_pt,
    header_pt,
    calendar_pt,
    lesson_pt,
    profile_pt,
    signup_pt,
    tasks_pt,
    test_pt,
    lemonade_pt,
    grades_pt,
    project_pt,
    editorial_pt,
    teams_pt,
    message_pt,
  ],
  en_MACMILLAN: [
    auth_en_macmillan,
    courses_en_macmillan,
    common_en_macmillan,
    header_en_macmillan,
    calendar_en_macmillan,
    lesson_en_macmillan,
    profile_en_macmillan,
    signup_en_macmillan,
    tasks_en_macmillan,
    test_en_macmillan,
    lemonade_en_macmillan,
    grades_en_macmillan,
    project_en_macmillan,
    editorial_en_macmillan,
    teams_en_macmillan,
  ],
  es_MACMILLAN: [
    auth_es_macmillan,
    courses_es_macmillan,
    common_es_macmillan,
    header_es_macmillan,
    calendar_es_macmillan,
    lesson_es_macmillan,
    profile_es_macmillan,
    signup_es_macmillan,
    tasks_es_macmillan,
    test_es_macmillan,
    lemonade_es_macmillan,
    grades_es_macmillan,
    project_es_macmillan,
    editorial_es_macmillan,
    teams_es_macmillan,
  ],
  en_GOTITBYME: [
    auth_en_gotitbyme,
    courses_en_gotitbyme,
    common_en_gotitbyme,
    header_en_gotitbyme,
    calendar_en_gotitbyme,
    lesson_en_gotitbyme,
    profile_en_gotitbyme,
    signup_en_gotitbyme,
    tasks_en_gotitbyme,
    test_en_gotitbyme,
    lemonade_en_gotitbyme,
    grades_en_gotitbyme,
    project_en_gotitbyme,
    editorial_en_gotitbyme,
    teams_en_gotitbyme,
    kanban_en_gotitbyme,
  ],
  es_GOTITBYME: [
    auth_es_gotitbyme,
    courses_es_gotitbyme,
    common_es_gotitbyme,
    header_es_gotitbyme,
    calendar_es_gotitbyme,
    lesson_es_gotitbyme,
    profile_es_gotitbyme,
    signup_es_gotitbyme,
    tasks_es_gotitbyme,
    test_es_gotitbyme,
    lemonade_es_gotitbyme,
    grades_es_gotitbyme,
    project_es_gotitbyme,
    editorial_es_gotitbyme,
    teams_es_gotitbyme,
    kanban_es_gotitbyme,
  ],
};
