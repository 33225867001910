import * as coreEntities from '_core/store/index';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useSystemSettings() {
  const settings = useSelector((state) => coreEntities.systemSettings.selectors.getSystemSettings(state));
  const [loading, setLoading] = useState(true);
  const [enableAdvancedProgramming, setEnableAdvancedProgramming] = useState(undefined);

  useEffect(() => {
    if (settings) {
      setEnableAdvancedProgramming(settings?.enable_advanced_programming === 1);
      setLoading(false);
    }
  }, [settings]);

  return { enableAdvancedProgramming, loadingSettings: loading };
}

export default useSystemSettings;
