export function loginOraculo(interval, submitLogin) {
  if (!window || !window.isSignedIn || !window.getTokenId) return;

  const { isSignedIn, getTokenId } = window;

  if (isSignedIn && isSignedIn()) {
    const tokenId = getTokenId();
    clearInterval(interval);
    submitLogin(tokenId);
  }
}

export function saveGlobalSession(sid) {
  if (!window || !window.saveGlobalSession) return;
  return window.saveGlobalSession(sid);
}

export function onClickLogin() {
  if (!window || !window.getLoginButton) return;
  return window.getLoginButton().onclick();
}

export function onClickLogout() {
  if (!window || !window.getLogoutButton) return;
  return window.getLogoutButton().onclick();
}

export function removeCookieTokenOraculo() {
  if (!window) return;

  const { isSignedIn, makeLogout } = window;
  if (makeLogout && window.makeLogout()) {
    return window.makeLogout();
  }
  if (isSignedIn && isSignedIn()) {
    onClickLogout();
  }
}

export function getOraculoToken() {
  if (!window || !window.getTokenId) return null;
  return window.getTokenId();
}

export function onClickSignup() {
  if (!window || !window.openSignUpPopUp) return;
  return window.openSignUpPopUp();
}

export function onSetRol(rol) {
  if (!window || !window.setRol) return;
  return window.setRol(rol);
}

export function onSetUsername(username) {
  if (!window || !window.setUsername) return;
  return window.setUsername(username);
}

export function checkUsername(username) {
  if (!window || !window.checkUsername) return;
  return window.checkUsername(username);
}
