import { FEATURE_FLAGS } from '_core/variables/constant';
import posthog from 'posthog-js';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getOrganization } from '../crud/organization.crud';
import { REDUCER_ORGANIZATION } from './conf';

export const actionTypes = {
  SetOrganization: 'organization/SET_ORGANIZATION',
  GetOrganization: 'organization/GET_ORGANIZATION',
  SetFeatureFlags: 'organization/SET_FEATURE_FLAGS',
  GetFeatureFlags: 'organization/GET_FEATURE_FLAGS',
};

const initialState = {
  lms: null,
  tenant: null,
  featureFlags: {
    [FEATURE_FLAGS.SHOW_IMMERSIVE_READER]: false,
    [FEATURE_FLAGS.SHOW_LEMONADE_FEEDBACK_GENERATOR]: false,
    [FEATURE_FLAGS.QUESTION_GENERATED_WITH_AI]: false,
    [FEATURE_FLAGS.SHOW_CREATE_VIDEOLESSON]: false,
    [FEATURE_FLAGS.FEEDBACK_GENERATED_WITH_AI]: false,
    [FEATURE_FLAGS.SHOW_CUSTOM_AVATAR]: false,
    [FEATURE_FLAGS.IS_GAMIFIED]: false,
    [FEATURE_FLAGS.ALLOW_MOVE_SESSIONS]: false,
    [FEATURE_FLAGS.SHOW_POWER_LESSON]: false,
    [FEATURE_FLAGS.SHOW_SELECT_TYPE_LESSON]: false,
    [FEATURE_FLAGS.SHOW_INFANTILE_01]: false,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetOrganization: {
      const { lms, tenant } = action.payload;
      return { ...state, lms: { ...lms }, tenant: tenant.guid, tenantData: tenant };
    }

    case actionTypes.SetFeatureFlags: {
      return { ...state, featureFlags: { ...state.featureFlags, ...action.payload } };
    }

    default:
      return state;
  }
};

export const selectors = {
  getLms: (state) => {
    return state[REDUCER_ORGANIZATION].lms;
  },
  getEntryPoint: (state) => {
    return state[REDUCER_ORGANIZATION].lms?.entryPoint;
  },
  getClient: (state) => {
    return state[REDUCER_ORGANIZATION].tenant;
  },
  getTenant: (state) => {
    return state[REDUCER_ORGANIZATION].tenantData;
  },
  getCanCreateCourse: (state) => {
    return state[REDUCER_ORGANIZATION].lms?.canCreateCourse;
  },
  getCanCreateGroup: (state) => {
    return state[REDUCER_ORGANIZATION].lms?.canCreateGroup;
  },
  getFeatureFlag: (state, flag) => {
    return state[REDUCER_ORGANIZATION].featureFlags[flag];
  },
  getFeatureFlags: (state) => {
    return state[REDUCER_ORGANIZATION].featureFlags;
  },
};

export const actions = {
  getOrganization: () => ({ type: actionTypes.GetOrganization, payload: {} }),
  setOrganization: (params) => ({ type: actionTypes.SetOrganization, payload: params }),
  setFeatureFlags: (flags) => ({ type: actionTypes.SetFeatureFlags, payload: flags }),
  getFeatureFlags: () => ({ type: actionTypes.GetFeatureFlags, payload: {} }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetOrganization, function* getOrganizationSaga() {
    const { data } = yield getOrganization();

    if (data && data.status === 'success' && data.data) {
      yield put(actions.setOrganization(data.data));
      // Llamar directamente a getFeatureFlags después de setOrganization
      yield put(actions.getFeatureFlags());
    }
  });

  yield takeLatest(actionTypes.GetFeatureFlags, function* getOrganizationSaga() {
    const isImmersiveReaderEnabled = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.SHOW_IMMERSIVE_READER);
    const showLemonadeFeedbackGenerator = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.SHOW_LEMONADE_FEEDBACK_GENERATOR);
    const isQuestionGeneratedWithAI = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.QUESTION_GENERATED_WITH_AI);
    const isGamified = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.IS_GAMIFIED);
    const allowMoveSessions = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.ALLOW_MOVE_SESSIONS);
    const showPowerLesson = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.SHOW_POWER_LESSON);
    const showSelectTypeLesson = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.SHOW_SELECT_TYPE_LESSON);
    const showInfantile01 = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.SHOW_INFANTILE_01);
    const showCreateVideolesson = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.SHOW_CREATE_VIDEOLESSON);
    const showFeedbackGeneratedWithAI = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.FEEDBACK_GENERATED_WITH_AI);
    const showCustomAvatar = yield call([posthog, posthog.getFeatureFlag], FEATURE_FLAGS.SHOW_CUSTOM_AVATAR);
    yield put(
      actions.setFeatureFlags({
        [FEATURE_FLAGS.SHOW_IMMERSIVE_READER]: Boolean(isImmersiveReaderEnabled),
        [FEATURE_FLAGS.SHOW_LEMONADE_FEEDBACK_GENERATOR]: Boolean(showLemonadeFeedbackGenerator),
        [FEATURE_FLAGS.QUESTION_GENERATED_WITH_AI]: Boolean(isQuestionGeneratedWithAI),
        [FEATURE_FLAGS.SHOW_CREATE_VIDEOLESSON]: Boolean(showCreateVideolesson),
        [FEATURE_FLAGS.FEEDBACK_GENERATED_WITH_AI]: Boolean(showFeedbackGeneratedWithAI),
        [FEATURE_FLAGS.SHOW_CUSTOM_AVATAR]: Boolean(showCustomAvatar),
        [FEATURE_FLAGS.IS_GAMIFIED]: Boolean(isGamified),
        [FEATURE_FLAGS.ALLOW_MOVE_SESSIONS]: Boolean(allowMoveSessions),
        [FEATURE_FLAGS.SHOW_POWER_LESSON]: Boolean(showPowerLesson),
        [FEATURE_FLAGS.SHOW_SELECT_TYPE_LESSON]: Boolean(showSelectTypeLesson),
        [FEATURE_FLAGS.SHOW_INFANTILE_01]: Boolean(showInfantile01),
      })
    );
  });
}
