import { CLIENT } from '../variables/constant';
const INFANTILE_EDUCATION_LEVELS = ['el-br-01', 'el1', 'el-es-01', '00000000-0000-1000-0000-000000000016'];

export function getEducationLevelName(educationYear, educationLevel, client, t, language) {
  if (client === CLIENT.EDELVIVES || client === CLIENT.GOTITBYME) {
    let name = '';
    let year = t('education-years:' + educationYear.guid).startsWith('ey') ? educationYear.name : t('education-years:' + educationYear.guid);
    let level = t('education-levels:' + educationLevel.guid).startsWith('el') ? educationLevel.name : t('education-levels:' + educationLevel.guid);
    if (language === 'eu') {
      name = level + ' ' + year;
    } else {
      name = year + ' ' + level;
    }
    return name;
  }
  return `${educationLevel.name}: ${educationYear.name}`;
}

export function isThereInfantileCourse(courses) {
  return courses.some((course) => isInfantile(course.education_level_guid));
}

export function isInfantile(educationLevel) {
  return INFANTILE_EDUCATION_LEVELS.includes(educationLevel);
}
