import i18next from 'i18next';
import 'moment/locale/ca';
import 'moment/locale/es';
import 'moment/locale/eu';
import 'moment/locale/gl';
import moment from 'moment/moment';

export const getDiffFormat = (date) => {
  let dateParsed = '';
  const diffDays = moment(new Date()).diff(moment(new Date(date)), 'days');
  if (diffDays === 0) {
    const diffHours = moment(new Date()).diff(moment(new Date(date)), 'hours');
    if (diffHours === 0) {
      const diffMinutes = moment(new Date()).diff(moment(new Date(date)), 'minutes');
      if (diffMinutes === 0) {
        dateParsed = i18next.t(`time:Now`);
      } else {
        dateParsed = i18next.t(`time:minutes ago`, { quantity: diffMinutes });
      }
    } else {
      dateParsed = i18next.t(`time:hours ago`, { quantity: diffHours });
    }
  } else if (diffDays > 30) {
    dateParsed = getDateFormat(date);
  } else {
    dateParsed = i18next.t(`time:days ago`, { quantity: diffDays });
  }
  return dateParsed;
};

export const getDateFormatted = (date) => {
  const dateDate = new Date(date);
  return moment(dateDate).format('DD-MM-YYYY hh:mm:ss');
};

export const getDateMoment = (date) => {
  return moment(date);
};
export const getDateFormat = (date) => {
  const dateDate = new Date(date);
  return moment(dateDate).format(i18next.language === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY');
};

export const getDateFormatWithHour = (date) => {
  const dateDate = new Date(date);
  return moment(dateDate).format(i18next.language === 'es' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm');
};

export const getDateFormatServer = (date) => {
  const dateDate = new Date(date);
  return moment(dateDate).format('YYYY-MM-DD HH:mm:ss');
};

export const getDateFormatServerInUTC = (date) => {
  const dateDate = new Date(date).toUTCString();
  return moment(dateDate).format('YYYY-MM-DD HH:mm:ss');
};

export const getDateCustomFormatted = (date, format) => {
  const dateDate = new Date(date);
  return moment(dateDate).format(format);
};

export const getDueDate = (date) => {
  let string = '';
  let dateDinamic = '';
  let style = '';
  const today = moment().format('DD-MM-YYYY');
  const tomorrow = moment().add(1, 'days').format('DD-MM-YYYY');
  const yesterday = moment().add(-1, 'days').format('DD-MM-YYYY');
  const dateParse = moment(date).format('DD-MM-YYYY');
  if (new Date() > new Date(date)) {
    style = 'late';
  }
  if (moment(new Date(date)).diff(moment(new Date()), 'hours') < 24 && moment(new Date(date)).diff(moment(new Date()), 'hours') >= 0) {
    style = 'soon';
  }
  if (yesterday === dateParse) {
    string = 'Due yesterday at X';
    dateDinamic = moment(date).format('HH:mm');
  } else if (tomorrow === dateParse) {
    string = 'Due tomorrow at X';
    dateDinamic = moment(date).format('HH:mm');
  } else if (today === dateParse) {
    string = 'Due today at X';
    dateDinamic = moment(date).format('HH:mm');
  } else {
    const diffDays = moment(new Date(date)).diff(moment(new Date()), 'days');
    if (diffDays <= 0) {
      if (diffDays >= -3) {
        string = 'Due X days ago';
        dateDinamic = diffDays * -1;
      } else {
        string = 'Due X date';
        dateDinamic = moment(date).format('dddd, MMMM DD');
      }
    } else {
      if (diffDays <= 3) {
        string = 'Due in X days';
        dateDinamic = diffDays;
      } else {
        string = 'Due on X';
        dateDinamic = moment(date).format('dddd, DD MMMM');
      }
    }
  }

  return { string, dateDinamic, style };
};

export const getDateWithWeekdayAndHour = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format('dddd DD MMM, HH:mm');
  }
  return null;
};

export const getPublishDate = (date) => {
  return moment(date).format('DD/MM/YYYY - HH:mm');
};

export const getDateWithoutTimeZone = (date) => {
  date = moment(date).toDate();
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const getUTCDateWithoutTimeZone = (date) => {
  let tempDate = new Date(date);
  return moment(new Date(tempDate.getTime() + tempDate.getTimezoneOffset() * 60000)).format('YYYY-MM-DD HH:mm:ss');
};

export const getLanguagesToCalendar = (language) => {
  let lang = language.toLowerCase() || 'es';
  if (lang === 'vl') {
    lang = 'ca';
  }
  return lang;
};

export const getDateWithWeekday = (date) => {
  return moment(date).format('dddd DD/MM/YYYY');
};

export function getBeaytySeconds(seconds) {
  if (seconds) {
    if (seconds < 60) {
      return `${seconds} ${i18next.t('common:Seconds', { count: seconds })}`;
    }

    const minuts = Math.floor(seconds / 60);
    const hours = Math.floor(minuts / 60);
    const restMinuts = minuts % 60;

    if (hours > 0) {
      return `${hours}h, ${restMinuts} ${i18next.t('common:Minutes', { count: restMinuts })}`;
    } else {
      return `${restMinuts} ${i18next.t('common:Minutes', { count: restMinuts })}`;
    }
  } else return null;
}
