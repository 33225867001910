import { sanitizeMessages } from '_core/utils/utils';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';

import { CLIENT } from '_core/variables/constant';
import {
  addCourseByCode,
  addGoogleDriveFileToMessage,
  createCourse,
  createMessage,
  deleteAttachedFile,
  deleteCourse,
  deleteMessage,
  editMessage,
  getAllCoursesMessages,
  getCourseMessages,
  getCourseMessagesAnswers,
  getCourseTimetable,
  getCourses,
  getOneCourseMessage,
  getTeacherCourses,
  setCourseTimetable,
  updateCourse,
} from '../crud/courses.crud';
import { apiMessagesAnswersMock } from '../storybook/modules/components/mock/posts';
import { actionTypes as actionAuth } from './auth.duck';
import { PERSIST_COURSES, REDUCER_COURSES } from './conf';
import { actionTypes as actionGroups } from './groups.duck';
import { selectors as selectorsOrganizations } from './organization.duck';
import { parseCourses, parsePosts, parseUsers } from './parsers';

export const actionTypes = {
  GetCourses: 'Course/GET_COURSES',
  SetCourses: 'Course/SET_COURSES',
  GetTeacherCourses: 'Course/GET_TEACHER_COURSES',
  SetTeacherCourses: 'Course/SET_TEACHER_COURSES',
  GetCurrentCourse: 'Course/GET_CURRENT_COURSE',
  SetCurrentCourse: 'Course/SET_CURRENT_COURSE',
  GetCurrentCourseTimetable: 'Course/GET_CURRENT_COURSE_TIMETABLE',
  SetCurrentCourseTimetable: 'Course/SET_CURRENT_COURSE_TIMETABLE',
  UpdateCurrentCourseTimeTable: 'Course/UPDATE_CURRENT_COURSE_TIMETABLE',
  StatusUpsert: 'Course/ERROR_UPSERT_COURSE',
  CreateCourse: 'Course/CREATE_COURSE',
  AddOneCourse: 'Course/ADD_ONE_COURSE',
  DeleteCourse: 'Course/DELETE_COURSE',
  RemoveCourse: 'Course/REMOVE_COURSE',
  UpdateCourse: 'Course/UPDATE_COURSE',
  ReplaceCourse: 'Course/REPLACE_COURSE',
  AddCourseByCode: 'Course/ADD_COURSE_BY_CODE',
  SetError: 'Course/SET_ERROR',
  GetCourseMessages: 'Course/GET_COURSE_MESSAGES',
  SetCourseMessages: 'Course/SET_COURSE_MESSAGES',
  GetAllCoursesMessages: 'Course/GET_ALL_COURSES_MESSAGES',
  SetAllCoursesMessages: 'Course/SET_ALL_COURSES_MESSAGES',
  AddOneGlobalMessage: 'Course/ADD_ONE_GLOBAL_MESSAGE',
  MarkMessageAsRead: 'Course/MARK_MESSAGE_AS_READ',
  GetCourseMessagesAnswers: 'Course/GET_COURSE_MESSAGES_ANSWERS',
  SetCourseMessagesAnswers: 'Course/SET_COURSE_MESSAGES_ANSWERS',
  CreateMessage: 'Course/POST_MESSAGE',
  AddOneMessage: 'Course/ADD_ONE_MESSAGE',
  DeleteMessage: 'Course/DELETE_MESSAGE',
  RemoveMessage: 'Course/REMOVE_MESSAGE',
  EditMessage: 'Course/PUT_MESSAGE',
  ReplaceMessage: 'Course/REPLACE_MESSAGE',
  GetOneCourseMessage: 'Course/GET_ONE_COURSE_MESSAGE',
  ReloadCourseMessage: 'Course/RELOAD_COURSE_MESSAGE',
  DeleteAttachedCourseFile: 'COURSE/DELETE_ATTACHED_FILE',
  GetCurrentCourseItems: 'COURSE/GET_CURRENT_COURSE_ITEMS',
  SetCurrentCourseItems: 'COURSE/SET_CURRENT_COURSE_ITEMS',
  UpdateGroupStreamSettings: 'COURSE/UPDATE_GROUP_STREAM_SETTINGS',
};

const initialState = {
  courses: null,
  teacherCourses: [],
  pagination: {
    left: 0,
    length: 0,
    offset: 0,
    pageSize: 0,
  },
  current: {},
  courseMessages: { messages: [], loading: false },
  loading: false,
  statusUpsert: null,
  error: false,
  currentCourseItems: [],
  allCoursesMessages: [],
};

export const reducer = persistReducer(
  { storage, key: PERSIST_COURSES, whitelist: ['courses', 'current', 'teacherCourses', 'currentCourseItems', 'allCoursesMessages'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GetCourses:
        return { ...state, loading: true };

      case actionTypes.SetCourses: {
        const { response } = action.payload;

        if (response && response.status === 'success' && response.data) {
          let newCoursesList = response.data.courses ? [...response.data.courses] : [];
          if (response.data.offset > 0) {
            newCoursesList = [...state.courses, ...response.data.courses];
          }
          return {
            ...state,
            courses: parseCourses(newCoursesList),
            pagination: {
              left: response.data.left,
              length: response.data.length,
              offset: response.data.offset,
              pageSize: response.data.pageSize,
            },
            loading: false,
          };
        }
        return { ...state, loading: false };
      }

      case actionTypes.SetTeacherCourses: {
        const { response } = action.payload;

        if (response && response.status === 'success' && response.data) {
          return {
            ...state,
            teacherCourses: response.data,
          };
        }
        return { ...state, loading: false };
      }

      case actionTypes.GetCurrentCourse:
        return { ...state, loading: true };

      case actionTypes.SetCurrentCourse: {
        let current = action.payload.response;
        return {
          ...state,
          current: current,
          loading: false,
        };
      }

      case actionTypes.GetCurrentCourseItems: {
        return { ...state, loading: true };
      }

      case actionTypes.SetCurrentCourseItems: {
        return { ...state, currentCourseItems: action.payload, loading: false };
      }

      case actionTypes.GetCurrentCourseTimetable:
        return { ...state, loading: true, statusUpsert: null };

      case actionTypes.SetCurrentCourseTimetable: {
        const { response } = action.payload;

        return {
          ...state,
          currentTimetable: response.data,
          //loading: false,
          //statusUpsert: 200,
        };
      }

      case actionTypes.StatusUpsert: {
        return { ...state, statusUpsert: action.payload, loading: false };
      }

      case actionTypes.CreateCourse: {
        return { ...state, loading: true, statusUpsert: null };
      }

      case actionTypes.AddOneCourse: {
        const { params } = action.payload;

        if (params && params.guid) {
          let newCoursesList = [...state.courses, params];

          return {
            ...state,
            courses: newCoursesList,
            statusUpsert: 200,
            loading: false,
          };
        }
        return { ...state, loading: false, statusUpsert: 200 };
      }

      case actionTypes.DeleteCourse: {
        return { ...state, statusUpsert: null };
      }

      case actionTypes.RemoveCourse: {
        const { guid } = action.payload;
        if (guid) {
          let newCoursesList = state.courses.filter((d) => {
            return d.guid !== guid[0];
          });

          return {
            ...state,
            courses: newCoursesList,
            statusUpsert: 200,
            loading: false,
          };
        }
        return { ...state };
      }

      case actionTypes.UpdateCourse: {
        return { ...state, statusUpsert: null };
      }

      case actionTypes.ReplaceCourse: {
        const { params } = action.payload;
        if (params) {
          let newCoursesList = state.courses.map((d) => {
            if (d.guid === params.guid) {
              let newCourse = { ...d, ...params };
              return { ...parseCourses([newCourse])[0] };
            }
            return d;
          });

          return {
            ...state,
            courses: newCoursesList,
            statusUpsert: 200,
            loading: false,
          };
        }
        return { ...state };
      }

      case actionTypes.UpdateGroupStreamSettings: {
        const { school_group_guid, school_group_stream } = action.payload;
        if (school_group_guid) {
          let newCoursesList = state.courses.map((course) => {
            if (course.school_group_guid === school_group_guid) {
              return {
                ...course,
                school_group_stream: school_group_stream,
              };
            } else return course;
          });

          return {
            ...state,
            courses: parseCourses(newCoursesList),
            loading: false,
            current: { ...state.current, school_group_stream: school_group_stream },
          };
        } else return state;
      }

      case actionTypes.AddCourseByCode: {
        return { ...state, error: '', loading: false };
      }

      case actionTypes.SetError: {
        const { error } = action.payload;
        return { ...state, error, loading: false };
      }

      case actionTypes.GetCourseMessages:
        return {
          ...state,
          courseMessages: { ...state.courseMessages, loading: true },
        };

      case actionTypes.SetCourseMessages: {
        let messages = action.payload.response;
        return {
          ...state,
          loading: false,
          courseMessages: {
            loading: false,
            total: messages.total,
            left: messages.left,
            pageSize: messages.pageSize,
            messages:
              action.payload.typeSet === 'setAll'
                ? [...state.courseMessages.messages, ...messages.messages]
                : messages.messages.filter((message) => !message.lesson_guid),
          },
        };
      }

      case actionTypes.GetAllCoursesMessages:
        return {
          ...state,
          allCoursesMessages: state.allCoursesMessages,
        };
      case actionTypes.SetAllCoursesMessages: {
        let messages = action.payload?.response?.messages;
        let allMessages = state.allCoursesMessages;
        messages =
          messages.length > 0
            ? messages.map((message) => {
                const existingMessage = allMessages.find((m) => m.guid === message.guid);
                if (existingMessage) {
                  if (existingMessage.text !== message.message) {
                    return { ...existingMessage, text: message.message, read: false, ...message };
                  }
                  return { ...existingMessage, ...message };
                } else {
                  return { ...message, global: true, pin: true, text: message.message };
                }
              })
            : [];
        return {
          ...state,
          loading: false,
          allCoursesMessages: [...messages],
        };
      }

      case actionTypes.MarkMessageAsRead: {
        let messageGuid = action.payload.response.messageGuid;
        let read = action.payload.response.read;
        let allMessages = state.allCoursesMessages.map((item) => {
          return item.guid === messageGuid ? { ...item, read: read } : item;
        });
        return {
          ...state,
          allCoursesMessages: allMessages,
        };
      }

      case actionTypes.GetCourseMessagesAnswers:
        return { ...state /*loading: true*/ };

      case actionTypes.SetCourseMessagesAnswers: {
        let message = action.payload.response;
        let comments = message.comments.reverse();
        let allMessages = state.courseMessages.messages.map((entry) =>
          entry.guid === message.guid ? { ...entry, comments: comments, commentsRead: true } : entry
        );

        return {
          ...state,
          loading: false,
          courseMessages: { ...state.courseMessages, messages: allMessages },
        };
      }

      case actionTypes.CreateMessage: {
        return {
          ...state,
          statusUpsert: null,
          courseMessages: { ...state.courseMessages, loading: true },
        };
      }

      case actionTypes.AddOneMessage: {
        const { params } = action.payload;

        if (params && params.guid) {
          if (params.parent_guid) {
            let message = action.payload.params;
            let allMessages = state.courseMessages.messages.map((entry) =>
              entry.guid === message.parent_guid
                ? { ...entry, comments: entry.comments ? [...entry.comments, message] : [message], amount_comments: entry.amount_comments + 1 || 1 }
                : entry
            );

            return {
              ...state,
              loading: false,
              courseMessages: { ...state.courseMessages, messages: allMessages, loading: false },
            };
          } else {
            let msg = params;
            let idx = state.courseMessages.messages.findIndex((message) => !message.is_pinned);

            let newMessages = [];
            if (msg.is_pinned) {
              newMessages = [msg, ...state.courseMessages.messages];
            } else {
              newMessages = [...state.courseMessages.messages];
              newMessages.splice(idx, 0, msg);
            }

            return {
              ...state,
              courseMessages: { ...state.courseMessages, messages: newMessages, newMessage: params, loading: false },
              statusUpsert: 200,
              loading: false,
            };
          }
        }
        return { ...state, loading: false, statusUpsert: 200 };
      }

      case actionTypes.AddOneGlobalMessage: {
        const { params } = action.payload;

        if (params && params.guid) {
          let msg = params;
          let newMessages = [];
          newMessages = [msg, ...state.courseMessages.messages];

          return {
            ...state,
            allCoursesMessages: newMessages,
            statusUpsert: 200,
            loading: false,
          };
        }
        return { ...state, loading: false, statusUpsert: 200 };
      }

      case actionTypes.DeleteMessage: {
        return {
          ...state,
          statusUpsert: null,
          courseMessages: { ...state.courseMessages /*, loading: true*/ },
        };
      }

      case actionTypes.RemoveMessage: {
        const { guid } = action.payload;
        const { parentGuid } = action.payload;
        if (guid) {
          let newMessages = state.courseMessages.messages.filter((d) => {
            return d.guid !== guid[0];
          });

          if (parentGuid) {
            newMessages = state.courseMessages.messages.map((entry) => {
              const comment = entry.comments ? entry.comments.filter((comment) => comment.guid !== guid[0]) : [];
              return entry.guid === parentGuid
                ? {
                    ...entry,
                    comments: comment,
                    amount_comments: entry.amount_comments - 1,
                  }
                : entry;
            });
          }

          return {
            ...state,
            courseMessages: { ...state.courseMessages, messages: newMessages },
            statusUpsert: 200,
            loading: false,
          };
        }
        return { ...state };
      }

      case actionTypes.EditMessage: {
        return {
          ...state,
          statusUpsert: null,
          courseMessages: { ...state.courseMessages, loading: true },
        };
      }

      case actionTypes.ReplaceMessage: {
        const { params } = action.payload;

        if (params && params.guid) {
          if (params.parentGuid) {
            let newMessages = state.courseMessages.messages.map((entry) => {
              const answers = entry.comments || [];
              if (entry.guid === params.parentGuid) {
                for (let i = 0; i < answers.length; i++) {
                  if (answers[i].guid === params.guid) {
                    answers[i] = { ...answers[i], message: params.message, is_pinned: params.is_pinned || false, message_for: params.message_for };
                  }
                }
              }

              return entry.guid === params.parentGuid
                ? {
                    ...entry,
                    comments: answers,
                  }
                : entry;
            });

            return {
              ...state,
              courseMessages: { ...state.courseMessages, messages: newMessages, loading: false },
              statusUpsert: 200,
              loading: false,
            };
          } else {
            let newMessages = state.courseMessages.messages.map((entry) => {
              if (entry.guid === params.guid)
                return { ...entry, message: params.message, is_pinned: params.is_pinned || false, message_for: params.message_for };
              else return entry;
            });

            return {
              ...state,
              courseMessages: { ...state.courseMessages, messages: newMessages, loading: false },
              statusUpsert: 200,
              loading: false,
            };
          }
        }
        return { ...state, loading: false, statusUpsert: 200 };
      }

      case actionTypes.getOneCourseMessage:
        return { ...state, loading: true };

      case actionTypes.ReloadCourseMessage: {
        let message = action.payload.response;
        let newMessages = state.courseMessages.messages.map((entry) => {
          if (entry.guid === message.guid) return message;
          else return entry;
        });

        return {
          ...state,
          courseMessages: { ...state.courseMessages, messages: newMessages, loading: false },
          statusUpsert: 200,
          loading: false,
        };
      }

      case actionGroups.RemoveOneGroup: {
        const school_group_guid = action.payload;
        if (school_group_guid) {
          let newCoursesList = state.courses.filter((course) => {
            return course.school_group_guid !== school_group_guid;
          });
          return {
            ...state,
            courses: newCoursesList,
            statusUpsert: 200,
            loading: false,
          };
        }

        return state;
      }

      case actionAuth.Logout: {
        //si logout, borrar estado redux
        return initialState;
      }

      default:
        return state;
    }
  }
);

export const selectors = {
  getCourses: (state) => {
    return state.entities && state.entities[REDUCER_COURSES] ? state.entities[REDUCER_COURSES].courses : null;
  },
  getTeacherCourses: (state) => {
    return state.entities && state.entities[REDUCER_COURSES] ? state.entities[REDUCER_COURSES].teacherCourses : null;
  },
  getCoursesOfGroup: (state, groupId) => {
    return state.entities && state.entities[REDUCER_COURSES]
      ? state.entities[REDUCER_COURSES].courses.filter((item) => item.school_group_guid === groupId)
      : null;
  },
  getCurrentCourse: (state) => {
    return state.entities && state.entities[REDUCER_COURSES] ? state.entities[REDUCER_COURSES].current : null;
  },
  getCurrentCourseItems: (state) => {
    return state.entities && state.entities[REDUCER_COURSES] ? state.entities[REDUCER_COURSES].currentCourseItems : null;
  },
  getPagination: (state) => {
    return state.entities && state.entities[REDUCER_COURSES] ? state.entities[REDUCER_COURSES].pagination : null;
  },
  isLoading: (state) => {
    return state.entities && state.entities[REDUCER_COURSES] ? state.entities[REDUCER_COURSES].loading : true;
  },
  getCurrentTimetable: (state) => {
    return state.entities && state.entities[REDUCER_COURSES] ? state.entities[REDUCER_COURSES].currentTimetable : null;
  },
  getUpsertStatus: (state) => {
    return (state.entities && state.entities[REDUCER_COURSES].statusUpsert) || false;
  },
  getError: (state) => {
    return (state.entities && state.entities[REDUCER_COURSES].error) || false;
  },
  getPaginationMessages: (state) => {
    return (state.entities && state.entities[REDUCER_COURSES].courseMessages) || [];
  },
  getMessages: (state) => {
    return (state.entities && parsePosts(state.entities[REDUCER_COURSES].courseMessages.messages)) || [];
  },
  getAllMessages: (state) => {
    return (state.entities && state.entities[REDUCER_COURSES].allCoursesMessages) || [];
  },
  messagesLoading: (state) => {
    return (state.entities && state.entities[REDUCER_COURSES].courseMessages.loading) || false;
  },
  newMessage: (state) => {
    return (state.entities && state.entities[REDUCER_COURSES].courseMessages.newMessage) || null;
  },

  getCourseUsers: (state) => {
    return state.entities && state.entities[REDUCER_COURSES] ? parseUsers(state.entities[REDUCER_COURSES].current.users) : [];
  },
};

export const actions = {
  getCourses: (params) => ({ type: actionTypes.GetCourses, payload: params }),
  fulfillCourses: (response) => ({ type: actionTypes.SetCourses, payload: { response } }),
  getTeacherCourses: (params) => ({ type: actionTypes.GetTeacherCourses, payload: params }),
  fullfillTeacherCourses: (response) => ({ type: actionTypes.SetTeacherCourses, payload: { response } }),
  getCurrentCourse: (courseGuid) => ({ type: actionTypes.GetCurrentCourse, payload: { courseGuid } }),
  setCurrentCourse: (response) => ({ type: actionTypes.SetCurrentCourse, payload: { response } }),
  setCurrentCourseItems: (response) => ({ type: actionTypes.SetCurrentCourseItems, payload: response }),
  getCurrentCourseTimetable: (guid) => ({ type: actionTypes.GetCurrentCourseTimetable, payload: guid }),
  setCurrentCourseTimetable: (response) => ({ type: actionTypes.SetCurrentCourseTimetable, payload: { response } }),
  updateCurrentCourseTimetable: (params) => ({ type: actionTypes.UpdateCurrentCourseTimeTable, payload: params }),
  setStatusUpsert: (params) => ({ type: actionTypes.StatusUpsert, payload: params }),
  createCourse: (params) => ({ type: actionTypes.CreateCourse, payload: { params } }),
  addOneCourse: (params) => ({ type: actionTypes.AddOneCourse, payload: { params } }),
  deleteCourse: (params) => ({ type: actionTypes.DeleteCourse, payload: { params } }),
  removeCourse: (params) => ({ type: actionTypes.RemoveCourse, payload: params }),
  updateCourse: (params) => ({ type: actionTypes.UpdateCourse, payload: { params } }),
  replaceCourse: (params) => ({ type: actionTypes.ReplaceCourse, payload: { params } }),
  addCourseByCode: (params) => ({ type: actionTypes.AddCourseByCode, payload: { params } }),
  setError: (error) => ({ type: actionTypes.SetError, payload: { error } }),
  getCourseMessages: (courseGuid, params, typeSet) => ({ type: actionTypes.GetCourseMessages, payload: { courseGuid, params, typeSet } }),
  setCourseMessages: (response) => ({ type: actionTypes.SetCourseMessages, payload: { response } }),
  getAllCoursesMessages: (params) => ({ type: actionTypes.GetAllCoursesMessages, payload: params }),
  setAllCoursesMessages: (response) => ({ type: actionTypes.SetAllCoursesMessages, payload: { response } }),
  markMessageAsRead: (params) => ({ type: actionTypes.MarkMessageAsRead, payload: { response: params } }),
  getCourseMessagesAnswers: (params) => ({ type: actionTypes.GetCourseMessagesAnswers, payload: params }),
  setCourseMessagesAnswers: (response) => ({ type: actionTypes.SetCourseMessagesAnswers, payload: { response } }),
  createMessage: (params) => ({ type: actionTypes.CreateMessage, payload: { params } }),
  addOneMessage: (params) => ({ type: actionTypes.AddOneMessage, payload: { params } }),
  addOneGlobalMessage: (params) => ({ type: actionTypes.AddOneGlobalMessage, payload: { params } }),
  deleteMessage: (params) => ({ type: actionTypes.DeleteMessage, payload: { params } }),
  removeMessage: (params) => ({ type: actionTypes.RemoveMessage, payload: params }),
  editMessage: (params) => ({ type: actionTypes.EditMessage, payload: { params } }),
  replaceMessage: (params) => ({ type: actionTypes.ReplaceMessage, payload: { params } }),
  getOneCourseMessage: (params) => ({ type: actionTypes.GetOneCourseMessage, payload: params }),
  reloadCourseMessage: (response) => ({ type: actionTypes.ReloadCourseMessage, payload: { response } }),
  deleteAttachedCourseFile: (params) => ({ type: actionTypes.DeleteAttachedCourseFile, payload: params }),
  updateGroupStreamSettings: (params) => ({ type: actionTypes.UpdateGroupStreamSettings, payload: params }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetCourses, function* getCoursesSaga(action) {
    const { data } = yield getCourses(action.payload);
    yield put(actions.fulfillCourses(data));
  });

  yield takeLatest(actionTypes.GetTeacherCourses, function* getTeacherCoursesSaga(action) {
    const { data } = yield getTeacherCourses(action.payload);
    yield put(actions.fullfillTeacherCourses(data));
  });

  yield takeLatest(actionTypes.GetCurrentCourse, function* getCurrentCourseSaga(action) {
    const { data } = yield getCourses(action.payload);
    // yield put(actions.fulfillCourses(data));

    if (data && data.status === 'success' && data.data) {
      yield put(actions.setCurrentCourse(data.data));
    } else {
      window.location.href = '/home';
    }
  });

  yield takeLatest(actionTypes.GetCurrentCourseTimetable, function* getCurrentCourseTimetableSaga(action) {
    const { data } = yield getCourseTimetable(action.payload);

    yield put(actions.setCurrentCourseTimetable(data));
  });

  yield takeLatest(actionTypes.UpdateCurrentCourseTimeTable, function* updateCurrentCourseTimeTableSaga(action) {
    const { data, error } = yield setCourseTimetable(action.payload);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setStatusUpsert(200));
      yield put(actions.setCurrentCourseTimetable(data));
    } else {
      const status = (error && error.data && error.data.error.code) || 400;
      yield put(actions.setStatusUpsert(status));
    }
  });

  yield takeLatest(actionTypes.CreateCourse, function* createCourseSaga(action) {
    const { data, error } = yield createCourse(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.addOneCourse(data.data));
    } else {
      const status = (error && error.data && error.data.error && error.data.error.code) || 400;
      yield put(actions.setStatusUpsert(status));
    }
  });

  yield takeLatest(actionTypes.DeleteCourse, function* deleteCourseSaga(action) {
    const { data } = yield deleteCourse(action.payload.params);
    if (data && data.status === 'success') yield put(actions.removeCourse(action.payload.params));
  });

  yield takeLatest(actionTypes.UpdateCourse, function* updateCourseSaga(action) {
    const { data } = yield updateCourse(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.replaceCourse(data.data));
    }
  });

  yield takeLatest(actionTypes.AddCourseByCode, function* updateCourseSaga(action) {
    const { data, error } = yield addCourseByCode(action.payload.params.code);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.getCourses());
    } else {
      yield put(actions.setError(error.data.error));
    }
  });

  yield takeLatest(actionTypes.GetCourseMessages, function* getCourseMessagesSaga(action) {
    const { data } = yield getCourseMessages(action.payload.courseGuid, action.payload.params);

    if (data && data.status === 'success' && data.data) {
      let messages = sanitizeMessages(data.data.messages);
      yield put(actions.setCourseMessages({ ...data.data, messages: messages }, action.payload.typeSet));
    } else {
    }
  });

  yield takeLatest(actionTypes.GetCourseMessagesAnswers, function* getCourseMessagesAnswersSaga(action) {
    const { data } = yield getCourseMessagesAnswers(action.payload);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.setCourseMessagesAnswers(data.data));
    } else {
      yield put(actions.setCourseMessagesAnswers(apiMessagesAnswersMock.data));
    }
  });

  yield takeLatest(actionTypes.GetAllCoursesMessages, function* getAllCoursesMessagesSaga(action) {
    const tenant = yield select((state) => selectorsOrganizations.getTenant(state));
    if (tenant?.guid === CLIENT.MASTER || tenant?.guid === CLIENT.TANGERINE) {
      const { data } = yield getAllCoursesMessages(action.payload);
      if (data && data.status === 'success' && data.data) {
        yield put(actions.setAllCoursesMessages(data.data));
      }
    }
  });

  yield takeLatest(actionTypes.CreateMessage, function* createMessageSaga(action) {
    const { data, error } = yield createMessage(action.payload.params);
    if (action.payload.params.fileDrive) {
      data.data.files = [];
      let responseFile;
      for (let fileDrive of action.payload.params.fileDrive) {
        responseFile = yield addGoogleDriveFileToMessage({
          courseGuid: action.payload.params.courseGuid,
          messageGuid: data.data.guid,
          oauthToken: fileDrive.oauthToken,
          fileId: fileDrive.id,
        });
        data.data.files.push({
          encoded_url: responseFile.data.data.uploaded_files[0].encoded_url,
          url: responseFile.data.data.uploaded_files[0].encoded_url,
          message_guid: data.data.guid,
        });
      }
    }
    if (data && data.status === 'success' && data.data) {
      yield put(actions.addOneMessage(data.data));
    } else {
      const status = (error && error.data && error.data.error && error.data.error.code) || 400;
      yield put(actions.setStatusUpsert(status));
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteMessageSaga(action) {
    const { data } = yield deleteMessage(action.payload.params);
    if (data && data.status === 'success') yield put(actions.removeMessage(action.payload.params));
  });

  yield takeLatest(actionTypes.EditMessage, function* editMessageSaga(action) {
    let { data } = yield editMessage(action.payload.params);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.replaceMessage(action.payload.params));
    }

    /*let { data } = yield getCourseMessages(action.payload.params.courseGuid, {});

    if (data && data.status === 'success' && data.data) {
      yield put(actions.setCourseMessages(data.data, 'setAll'));
    }*/
  });

  yield takeLatest(actionTypes.GetOneCourseMessage, function* getOneCourseMessageSaga(action) {
    const { data } = yield getOneCourseMessage(action.payload);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.reloadCourseMessage(data.data));
    }
  });

  yield takeLatest(actionTypes.DeleteAttachedCourseFile, function* deleteAttachedCourseFileSaga(action) {
    const { data } = yield deleteAttachedFile(action.payload);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.getOneCourseMessage(action.payload));
    }
  });
}
