import { useRouter } from '_core/hooks/useRouter';
import LayoutDefault from '_core/layout/LayoutDefault';
import Loading from '_core/modules/atoms/Loading';
import * as auth from '_core/store/auth.duck';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

function SamlCallback({ login, t }) {
  const { token } = useParams(); //Ex: {token}&{application}
  const router = useRouter();

  useEffect(() => {
    const { query } = router;
    const { loginContext } = query;

    if (token !== 'error') {
      login({ token, loginContext });
    }
  }, []);

  return (
    <>
      <LayoutDefault>
        <div>
          <Loading loadingText={t('Authenticating')} />
        </div>
      </LayoutDefault>
    </>
  );
}

SamlCallback.propTypes = {
  login: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('login')(connect(null, auth.actions)(SamlCallback));
